<template>
  <div class="notFound">
    <div class="noFoundimg" />
  </div>
</template>

<script>
export default {
name: 'noPage'
}
</script>

<style lang="scss" scoped>
.notFound {
  width: 100%;
  height: calc(80vh);
  display: flex;
  align-items: center;
  justify-content: center;

  .noFoundimg {
    background-image: url('@/assets/img/pageNotFound.png');
    background-repeat: round;
    width: 50%;
    height: 50%;
  }
}
</style>