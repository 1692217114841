(function (document) {
	var dcl = document.documentElement,//获取到html元素
		ww; //申明wh变量
		// wh; //申明wh变量

	function setRootRem() {
		ww = dcl.clientWidth; //获取元素的宽度
		// wh = dcl.clientHeight; //获取元素的高度
		if(ww>550){
			// console.log('wh：', wh)
			// console.log('ww', ww)
			// console.log('flag:', ww>1000)
			// console.log('font:', 8 * (ww / 1536) + 'px')
			let changeFont = 9 * (ww / 1550); // 1206为设计稿高度   改变font-size的大小
			if (changeFont > 9.5) {
				dcl.style.fontSize = '9.5px'
			} else {
				dcl.style.fontSize = 9 * (ww / 1550) + 'px'; // 1206为设计稿高度   改变font-size的大小
			}
		} else {
			dcl.style.fontSize = '4px'
		}
	}
	setRootRem();
	document.addEventListener('DOMContentLoaded', setRootRem, false);  //DOMContentLoaded事件 当初始的html文档全部加载完成后触发 不等待css样式表的加载
	window.addEventListener('resize', setRootRem, false); //resize事件 监听窗口的大小来触发事件
})(document);


// scale缩放
// function setCalc () {
// 	// 当前页面宽度相对于 1920宽的缩放比例，可根据自己需要修改。
// 	const scaleX = document.documentElement.clientWidth / 1920
// 	const scaleY = document.documentElement.clientHeight / 1080
// 	const app = document.getElementById('app')
// 	// 需要取缩放倍数较小的，因为需要宽高都兼容
// 	if(scaleX > scaleY) {
// 		app.style.transform = `translate(-50%, -50%) scale(${scaleY})`
// 	} else {
// 		app.style.transform = `translate(-50%, -50%) scale(${scaleX})`
// 	}
// }
// // 改变窗口大小时重新设置 rem
// window.onresize = function () {
// 	setCalc()
// }

// export default function init() {
// 	setCalc()
// }