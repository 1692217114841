<template>
  <div class="wrapper_content">
    <p class="th_main_title"><span>特浩</span>产品</p>
    <!-- <div v-for="brand in brands" :key="brand.name"> -->
    <div style="width: 100%; overflow: hidden">
      <div class="top">
        <ul ref="menuTop">
          <li @mouseenter="stopTimer(0)" @mouseleave="setTimer">
            <p>美隆SUP<span>电容</span></p>
          </li>
          <li @mouseenter="stopTimer(1)" @mouseleave="setTimer">
            <p>美隆SUP<span>电阻</span></p>
          </li>
          <li @mouseenter="stopTimer(2)" @mouseleave="setTimer">
            <p>三星<span>Samsung</span></p>
          </li>
          <li @mouseenter="stopTimer(3)" @mouseleave="setTimer">
            <p>美键达<span>MJDA</span></p>
          </li>
          <li @mouseenter="stopTimer(4)" @mouseleave="setTimer">
            <p>长电/长晶<span>JSCJ</span></p>
          </li>
          <li @click="goProduct">
            <p>更多<span>产品</span></p>
          </li>
        </ul>
      </div>
      <!-- <div v-for="product in brand.products" :key="product.type"> -->
      <div
        ref="scrollImgs"
        class="proImg"
        @mouseenter="stopTimer(-1)"
        @mouseleave="setTimer"
      >
        <!-- 美隆贴片电容 -->
        <div>
          <div class="product-top" @click="goDetail(supTop)">
            <div class="pic">
              <el-image
                class="proPic"
                :src="supTop.img"
                style="transform: rotate(0.5deg)"
                fit="fill"
                alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
                lazy
              />
            </div>
            <div class="text">
              <p>{{ supTop.title }}</p>
              <p>产品封装:{{ supTop.size }}</p>
            </div>
          </div>
          <div class="product-list">
            <div
              class="item"
              v-for="pro in proSUP"
              :key="pro.index"
              @click="goDetail(pro)"
            >
              <div class="pic">
                <el-image
                  class="proPic"
                  :src="pro.img"
                  fit="contain"
                  alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
                  lazy
                />
              </div>
              <div class="text">
                <p>{{ pro.title }}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 美隆贴片电阻 -->
        <div>
          <div class="product-top" @click="goDetail(supTop1)">
            <div class="pic">
              <el-image
                class="proPic"
                :src="supTop1.img"
                style="transform: rotate(0.5deg)"
                fit="fill"
                alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
                lazy
              />
            </div>
            <div class="text">
              <p>{{ supTop1.title }}</p>
              <p>产品封装:{{ supTop1.size }}</p>
            </div>
          </div>
          <div class="product-list">
            <div
              class="item"
              v-for="pro in proSUP1"
              :key="pro.index"
              @click="goDetail(pro)"
            >
              <div class="pic">
                <el-image
                  class="proPic"
                  :src="pro.img"
                  fit="contain"
                  alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
                  lazy
                />
              </div>
              <div class="text">
                <p>{{ pro.title }}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 三星贴片电容 -->
        <div>
          <div class="product-top" @click="goDetail(samsung)">
            <div class="pic">
              <el-image
                class="proPic"
                :src="samsung.img"
                style="transform: rotate(0.5deg)"
                fit="fill"
                alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
                lazy
              />
            </div>
            <div class="text">
              <p>{{ samsung.title }}</p>
              <p>产品封装:{{ samsung.size }}</p>
            </div>
          </div>
          <div class="product-list">
            <div
              class="item"
              v-for="pro in proSamsung"
              :key="pro.index"
              @click="goDetail(pro)"
            >
              <div class="pic">
                <el-image
                  class="proPic"
                  :src="pro.img"
                  fit="contain"
                  alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
                  lazy
                />
              </div>
              <div class="text">
                <p>{{ pro.title }}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- MJDA二三极管 -->
        <div>
          <div class="product-top" @click="goDetail(MJDA)">
            <div class="pic">
              <el-image
                class="proPic"
                :src="MJDA.img"
                style="transform: rotate(0.5deg)"
                fit="fill"
                alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
                lazy
              />
            </div>
            <div class="text">
              <p>{{ MJDA.title }}</p>
              <p>产品封装:{{ MJDA.size }}</p>
            </div>
          </div>
          <div class="product-list">
            <div
              class="item"
              v-for="pro in proMJDA"
              :key="pro.index"
              @click="goDetail(pro)"
            >
              <div class="pic">
                <el-image
                  class="proPic"
                  :src="pro.img"
                  fit="contain"
                  alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
                  lazy
                />
              </div>
              <div class="text">
                <p>{{ pro.title }}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 长电二三极管 -->
        <div>
          <div class="product-top" @click="goDetail(JSCJ)">
            <div class="pic">
              <el-image
                class="proPic"
                :src="JSCJ.img"
                style="transform: rotate(0.5deg)"
                fit="fill"
                alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
                lazy
              />
            </div>
            <div class="text">
              <p>{{ JSCJ.title }}</p>
              <p>产品封装:{{ JSCJ.size }}</p>
            </div>
          </div>
          <div class="product-list">
            <div
              class="item"
              v-for="pro in proJSCJ"
              :key="pro.index"
              @click="goDetail(pro)"
            >
              <div class="pic">
                <el-image
                  class="proPic"
                  :src="pro.img"
                  fit="contain"
                  alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
                  lazy
                />
              </div>
              <div class="text">
                <p>{{ pro.title }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "thProduct",
  data() {
    return {
      count: 0, // 对滚动次数计数
      loadCount: 1,
      total: 5, // 一共可以滚动的页数
      timer: null,
      supTop1: {
        // 第一个单独处理
        title: "RR2012(0805)L334JT-美隆贴片电阻",
        type: "RR2012(0805)L334JT",
        size: "0805(2012)",
        img: require("@/assets/img/product/0805-330KJ.png"),
        value: "330KΩ",
        power: "1/8W",
        precision: "±5%",
      },
      proSUP1: [
        //list中的东西
        {
          title: "RR2012(0805)L1200FT-美隆贴片电阻",
          type: "RR2012(0805)L1200FT",
          size: "0805(2012)",
          img: require("@/assets/img/product/0805-120RF.png"),
          value: "120Ω",
          power: "1/8W",
          precision: "±1%",
        },
        {
          title: "RR3216(LE06)L200JT-美隆贴片电阻",
          type: "RR3216(LE06)L200JT",
          size: "1206(3216)",
          img: require("@/assets/img/product/1206-20RJ.png"),
          value: "20Ω",
          power: "1/4W",
          precision: "±5%",
        },
        {
          title: "RR1005(0402)L4022FT-美隆贴片电阻",
          type: "RR1005(0402)L4022FT",
          size: "0402(1005)",
          img: require("@/assets/img/product/0402-40.2KF.png"),
          value: "40.2KΩ",
          power: "1/16W",
          precision: "±1%",
        },
        {
          title: "RR1608(0603)L3603FT-美隆贴片电阻",
          type: "RR1608(0603)L3603FT",
          size: "0603(1608)",
          img: require("@/assets/img/product/0603-360KF.png"),
          value: "360KΩ",
          power: "1/10W",
          precision: "±1%",
        },
      ],
      supTop: {
        // sup电容
        title: "C0805C0G101J500NTED-美隆贴片电容",
        type: "C0805C0G101J500NTED",
        size: "0805(2012)",
        img: require("@/assets/img/product/0805-100pF.png"),
        containValue: "100pF",
        voltage: "50V",
        precision: "±5%",
        material: "C0G",
        thickness: "0.6mm",
      },
      proSUP: [
        //list中的东西 sup电容
        {
          // sup电容
          title: "C0805X7R104K500NTFD-美隆贴片电容",
          type: "C0805X7R104K500NTFD",
          size: "0805(2012)",
          img: require("@/assets/img/product/0805-100nF.png"),
          containValue: "100nF",
          voltage: "50V",
          precision: "±10%",
          material: "X7R",
          thickness: "0.85mm",
        },
        {
          // sup电容
          title: "C0805X7R392K500NTFD-美隆贴片电容",
          type: "C0805X7R392K500NTFD",
          size: "0805(2012)",
          img: require("@/assets/img/product/0805-3.9nF.png"),
          containValue: "3.9nF",
          voltage: "50V",
          precision: "±10%",
          material: "X7R",
          thickness: "0.85mm",
        },
        {
          // sup电容
          title: "C0805X7R333K500NTFD-美隆贴片电容",
          type: "C0805X7R333K500NTFD",
          size: "0805(2012)",
          img: require("@/assets/img/product/0805-33nF.png"),
          containValue: "33nF",
          voltage: "50V",
          precision: "±10%",
          material: "X7R",
          thickness: "0.85mm",
        },
        {
          // sup电容
          title: "C0805X7R223K500NTFD-美隆贴片电容",
          type: "C0805X7R223K500NTFD",
          size: "0805(2012)",
          img: require("@/assets/img/product/0805-22nF.png"),
          containValue: "22nF",
          voltage: "50V",
          precision: "±10%",
          material: "X7R",
          thickness: "0.85mm",
        },
      ],
      samsung: {
        title: "CL21A475KBQNNNE-三星贴片电容",
        brand: "三星(Samsung)",
        type: "CL21A475KA",
        size: "0805(2012)",
        img: require("@/assets/img/product/2012-4.7uF.png"),
        containValue: "4.7uF",
        voltage: "50V",
        precision: "±10%",
        material: "X5R",
        thickness: "1.25mm",
      },
      proSamsung: [
        //list中的东西 samsung电容
        {
          title: "CL21B225KAFNNNE-三星贴片电容",
          brand: "三星(Samsung)",
          type: "CL21B225KAFNNNE",
          size: "0805(2012)",
          img: '',
          // img: require("@/assets/img/product/2012-2.2uF.png"),
          containValue: "2.2uF",
          voltage: "25V",
          precision: "±10%",
          material: "X7R",
          thickness: "1.25mm",
        },
        {
          // samsung
          title: "CL21B105KBFNNNE-三星贴片电容",
          brand: "三星(Samsung)",
          type: "CL21B105KBFNNNE",
          size: "0805(2012)",
          img: '',
          // img: require("@/assets/img/product/2012-1uF.png"),
          containValue: "1uF",
          voltage: "50V",
          precision: "±10%",
          material: "X7R",
          thickness: "1.25mm",
        },
        {
          title: "CL21A106KAYNNNE-三星贴片电容",
          brand: "三星(Samsung)",
          type: "CL21A106KAYNNNE",
          size: "0805(2012)",
          img: '',
          // img: require("@/assets/img/product/2012-10uF.png"),
          containValue: "10uF",
          voltage: "25V",
          precision: "±10%",
          material: "X5R",
          thickness: "1.25mm",
        },
        {
          title: "CL21B224KBFNFNE-三星贴片电容",
          brand: "三星(Samsung)",
          type: "CL21B224KBFNFNE",
          size: "0805(2012)",
          img: '',
          // img: require("@/assets/img/product/2012-220nF.png"),
          containValue: "220nF",
          voltage: "50V",
          precision: "±10%",
          material: "X7R",
          thickness: "1.25mm",
        },
      ],
      MJDA: {
        title: "M7-美键达二极管",
        type: "M7",
        brand: "美键达(MJDA)",
        img: require("@/assets/img/product/m7.png"),
        size: "DO-214AC",
      },
      proMJDA: [
        {
          title: "SS24-美键达二极管",
          type: "SS24",
          brand: "美键达(MJDA)",
          img: '',
          // img: require("@/assets/img/product/ss24.png"),
          size: "DO-214AC",
        },
        {
          title: "SS14-美键达二极管",
          type: "SS14",
          brand: "美键达(MJDA)",
          img: '',
          // img: require("@/assets/img/product/ss14.png"),
          size: "DO-214AC",
        },
        {
          title: "S8050 J3Y-美键达三极管",
          type: "S8050 J3Y",
          brand: "美键达(MJDA)",
          img: '',
          // img: require("@/assets/img/product/s8050-J3Y.png"),
          size: "SOT-23",
        },
        {
          title: "SS8550 Y2-美键达三极管",
          type: "SS8550 Y2",
          brand: "美键达(MJDA)",
          img: '',
          // img: require("@/assets/img/product/ss8550-Y2.png"),
          size: "SOT-23",
        },
      ],
      JSCJ: {
        title: "1N4148WS-长电/长晶开关二极管",
        type: "1N4148WS",
        brand: "长电/长晶（JCET）",
        img: require("@/assets/img/product/1N4148WS.png"),
        size: "SOD-323",
      },
      proJSCJ: [
        {
          title: "1N4148W T4-长电/长晶开关二极管",
          type: "1N4148W T4",
          brand: "长电/长晶（JCET）",
          img: '',
          // img: require("@/assets/img/product/1N4148W-T4.png"),
          size: "SOT-123",
        },
        {
          title: "CJ431-长电/长晶稳压电路",
          type: "CJ431",
          brand: "长电/长晶（JCET）",
          img: '',
          // img: require("@/assets/img/product/CJ431.png"),
          size: "SOT-23",
        },
        {
          title: "BZT52C8V2-长电/长晶稳压二极管",
          type: "BZT52C8V2",
          brand: "长电/长晶（JCET）",
          img: '',
          // img: require("@/assets/img/product/BZT52C8V2.png"),
          size: "SOD-123",
        },
        {
          title: "SS8050-长电/长晶稳压三极管",
          type: "SS8050",
          brand: "长电/长晶（JCET）",
          img: '',
          // img: require("@/assets/img/product/ss8050.png"),
          size: "SOT-23",
        },
      ],
      // 下面 用于进行懒加载的替换
      copyproSamsung: [
        //list中的东西 samsung电容
        {
          title: "CL21B225KAFNNNE-三星贴片电容",
          brand: "三星(Samsung)",
          type: "CL21B225KAFNNNE",
          size: "0805(2012)",
          img: require("@/assets/img/product/2012-2.2uF.png"),
          containValue: "2.2uF",
          voltage: "25V",
          precision: "±10%",
          material: "X7R",
          thickness: "1.25mm",
        },
        {
          // samsung
          title: "CL21B105KBFNNNE-三星贴片电容",
          brand: "三星(Samsung)",
          type: "CL21B105KBFNNNE",
          size: "0805(2012)",
          img: require("@/assets/img/product/2012-1uF.png"),
          containValue: "1uF",
          voltage: "50V",
          precision: "±10%",
          material: "X7R",
          thickness: "1.25mm",
        },
        {
          title: "CL21A106KAYNNNE-三星贴片电容",
          brand: "三星(Samsung)",
          type: "CL21A106KAYNNNE",
          size: "0805(2012)",
          img: require("@/assets/img/product/2012-10uF.png"),
          containValue: "10uF",
          voltage: "25V",
          precision: "±10%",
          material: "X5R",
          thickness: "1.25mm",
        },
        {
          title: "CL21B224KBFNFNE-三星贴片电容",
          brand: "三星(Samsung)",
          type: "CL21B224KBFNFNE",
          size: "0805(2012)",
          img: require("@/assets/img/product/2012-220nF.png"),
          containValue: "220nF",
          voltage: "50V",
          precision: "±10%",
          material: "X7R",
          thickness: "1.25mm",
        },
      ],
      copyproMJDA: [
        {
          title: "SS24-美键达二极管",
          type: "SS24",
          brand: "美键达(MJDA)",
          img: require("@/assets/img/product/ss24.png"),
          size: "DO-214AC",
        },
        {
          title: "SS14-美键达二极管",
          type: "SS14",
          brand: "美键达(MJDA)",
          img: require("@/assets/img/product/ss14.png"),
          size: "DO-214AC",
        },
        {
          title: "S8050 J3Y-美键达三极管",
          type: "S8050 J3Y",
          brand: "美键达(MJDA)",
          img: require("@/assets/img/product/s8050-J3Y.png"),
          size: "SOT-23",
        },
        {
          title: "SS8550 Y2-美键达三极管",
          type: "SS8550 Y2",
          brand: "美键达(MJDA)",
          img: require("@/assets/img/product/ss8550-Y2.png"),
          size: "SOT-23",
        },
      ],
      copyproJSCJ: [
        {
          title: "1N4148W T4-长电/长晶开关二极管",
          type: "1N4148W T4",
          brand: "长电/长晶（JCET）",
          img: require("@/assets/img/product/1N4148W-T4.png"),
          size: "SOT-123",
        },
        {
          title: "CJ431-长电/长晶稳压电路",
          type: "CJ431",
          brand: "长电/长晶（JCET）",
          img: require("@/assets/img/product/CJ431.png"),
          size: "SOT-23",
        },
        {
          title: "BZT52C8V2-长电/长晶稳压二极管",
          type: "BZT52C8V2",
          brand: "长电/长晶（JCET）",
          img: require("@/assets/img/product/BZT52C8V2.png"),
          size: "SOD-123",
        },
        {
          title: "SS8050-长电/长晶稳压三极管",
          type: "SS8050",
          brand: "长电/长晶（JCET）",
          img: require("@/assets/img/product/ss8050.png"),
          size: "SOT-23",
        },
      ],
    };
  },
  watch: {},
  mounted() {
    this.changeLeft();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    goProduct() {
      window.open('https://itehao.1688.com/page/index.html?spm=0.0.wp_pc_common_header_companyName_undefined.0', '_blank');
      window.scrollTo(0, 0);
    },
    goDetail(clickObj) {
      sessionStorage.setItem("clickObj", JSON.stringify(clickObj));
      this.$router.push("/thProductDetail");
      window.scrollTo(0, 0);
    },
    interFun1() {}, // 调试用
    interFun() {
      this.count = this.count + 1;
      this.$refs.scrollImgs.style.left = -100 * this.count + "%"; // 向左位移
      if (this.count > 0) {
        this.$refs.menuTop.children[this.count - 1].classList.remove(
          "activeTitle"
        ); // 去除移动前的激活状态
      }
      if (this.count < this.total) {
        // 避免最后一次报错 下标越界
        this.$refs.menuTop.children[this.count].classList.add("activeTitle");
      }
      if (this.count === this.total) {
        // 处理最后一个跳到第一个
        this.$refs.scrollImgs.style.left = 0 + "rem";
        this.$refs.menuTop.children[this.total - 1].classList.remove(
          "activeTitle"
        ); // 去除最后一个的激活状态
        this.$refs.menuTop.children[0].classList.add("activeTitle"); // 把第一个设置为激活状态
        this.count = 0;
      }
      if (this.loadCount < 4) {
        this.lazyLoadImg()
      }
    },
    lazyLoadImg() { // 根据左移情况把图片地址赋值 每次都更新往后2个的
      switch(this.count) {
        case 1: this.proSamsung = this.copyproSamsung; this.loadCount= 2; break;
        case 2: this.proMJDA = this.copyproMJDA; this.loadCount= 3; break;
        case 3: this.proJSCJ = this.copyproJSCJ; this.loadCount= 4; break;
        default: return
      }
    },
    lazyLoadImg2(nowIndex) { // 根据左移情况把图片地址赋值 每次都更新往后1个的
      switch(nowIndex) {
        case 1: this.loadCount= 1; break;
        case 2: this.loadCount= 1; break;
        case 3: this.proSamsung = this.copyproSamsung; break;
        case 4: this.proMJDA = this.copyproMJDA; this.loadCount= 3; break;
        case 5: this.proJSCJ = this.copyproJSCJ; this.loadCount= 4; break;
        default: return
      }
    },
    changeLeft() {
      this.$refs.menuTop.children[0].classList.add("activeTitle"); // 默认第一个是激活
      this.timer = setInterval(this.interFun, 5000);
    },
    stopTimer(nowIndex) {
      this.lazyLoadImg2(nowIndex+1)
      if (nowIndex !== -1) {
        this.$refs.menuTop.children[this.count].classList.remove("activeTitle"); // 去除上一个的激活状态
        this.count = nowIndex;
        this.$refs.scrollImgs.style.left = -100 * this.count + "%"; // 向左位移
        this.$refs.menuTop.children[nowIndex].classList.add("activeTitle"); // 把当前设置为激活状态
      }
      clearInterval(this.timer);
    },
    setTimer() {
      this.timer = setInterval(this.interFun, 5000);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper_content {
  border-bottom: 0.1rem solid #0283c9;
  padding-bottom: 3.2rem;
  display: flex;
}

.proImg {
  float: left;
  width: 500%;
  position: relative;
  left: 0rem;
  transition: left 1s;
  overflow: auto; // 这行去掉懒加载
}

.product-list {
  width: 10%;
  overflow: hidden;
  display: inline-block;
  float: left;

  .item {
    height: 24rem;
    float: left;
    margin-top: 1rem;
    margin-left: 1.5%;
    width: 48.5%;
    overflow: hidden;
    background: #fff;
    cursor: pointer;

    .text {
      p {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .pic {
      overflow: hidden;
      border-bottom: #f2f2f2 solid 0.1rem;

      .proPic {
        display: block;
        margin: 0 auto;
        width: 18.5rem;
        height: 18.5rem;
        transition: all 0.5s;
      }

      .tit {
        padding: 0 2rem;
        height: 5.4rem;
        line-height: 5.4rem;
        font-size: 1.8rem;
        color: #333;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.product-top {
  width: 10%;
  height: 49rem;
  overflow: hidden;
  background: #fff;
  cursor: pointer;
  transition: all 0.5s;
  float: left;
  display: inline-block;
  margin-top: 1rem;
  box-sizing: border-box;

  p {
    height: 3rem;
    line-height: 3rem;
    font-size: 1.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .text {
    padding: 0px 3.8rem 0;
    overflow: hidden;

    p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .pic {
    height: 35.8rem;
    overflow: hidden;
    border-bottom: #f2f2f2 solid 0.1rem;
    transition: all 0.5s;

    .proPic {
      display: block;
      margin: 0 auto;
      width: 35.8rem;
      height: 35.8rem;
      transition: all 0.5s;
    }
  }
}

.product-top:hover .pic .proPic {
  scale: 1.1;
}

.product-top:hover {
  color: #0083ca;
  font-weight: 700;
}

.item:hover .pic .proPic {
  scale: 1.1;
}

.item:hover {
  color: #0083ca;
  font-weight: 700;
}

.top {
  width: 100%;
  float: left;
}

ul {
  list-style: none;
  display: flex;
  width: 100%;
  margin: 0px;
  padding-left: 0px;
  background-color: #0083ca;
  cursor: pointer;

  li {
    float: left;
    width: 16.666%;
    text-align: center;
    background-color: #0083ca;

    p {
      display: block;
      padding: 1rem;
      line-height: 3rem;
      font-size: 1.8rem;
      color: #fff;
      overflow: hidden;
      margin: 0px;

      span {
        display: block;
      }
    }
  }
}
.activeTitle {
  background-color: #83bf34;
}
li:hover {
  background-color: #83bf34;
}
// @media (max-width: 576px) {  
//   .product-top .pic {
//     height: 41rem;
//   }
// }
</style>