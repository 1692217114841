<template>
  <div class="mainBox">
    <div class="flexbox1">
      <p class="title1"><span>联系</span>特浩</p>
      <div class="content_Contact thBorder">
        <p>
          特浩电子前身为深圳美隆电子上海分公司，于2018年正式成立更名为上海特浩电子科技有限公司.成立以来一直与国内电子元器件代理商以及原厂保持紧密合作，致力打造成为国内电子元器件供应优质服务商！
        </p>
        <p>
          主营贴片电阻电容，二三极管及周边配套电子元器件，专注提升自身发展策略，为客户提供更优质的服务
        </p>
        <p>
          目前精供的品牌有：美隆SUP，长电/长晶JCET，美键达MJDA，公司常备大量现货，在深圳、上海均建立了大型仓储，主要以现货形式销售，产品型号齐全拥有完整的型号库存及优秀的销售及售后团队。
        </p>
        <p class="phoneNum">欢迎新老客户合作咨询：<span>021-61032309</span></p>
      </div>
      <p class="divider"></p>
      <div class="warpper_contactBox">
        <div class="iconArea">
          <div class="icon">
            <el-image
              style="width: 3.2rem;"
              :src="require('@/assets/img/zuoji.png')"
              fit="fill"
              alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
              lazy
            />
          </div>
          <p><strong>电话号码</strong></p>
          <p>021-61032309</p>
        </div>
        <div class="iconArea">
          <div class="icon">
            <el-image
              style="width: 3.2rem;"
              :src="require('@/assets/img/phone.png')"
              fit="fill"
              alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
              lazy
            />
          </div>
          <p><strong>手机联系</strong></p>
          <p>13501645968</p>
        </div>
        <div class="iconArea goToQQ" @click="goToQQ">
          <div class="icon">
            <el-image
              style="width: 3.2rem;"
              :src="require('@/assets/img/qq1.png')"
              fit="fill"
              alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
              lazy
            />
          </div>
          <p><strong>QQ联系</strong></p>
          <p>3002947144</p>
        </div>
        <div class="arCodeArea">
          <el-image
            style="width: 14rem;margin-top: 0.5rem;"
            :src="require('@/assets/img/vxVrCode.png')"
            fit="fill"
            alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
            lazy
          />
          <p>微信扫码</p>
        </div>
      </div>
      <p class="divider"></p>
      <p class="title1 title2">特浩<span>位置</span></p>
      <p>上海市黄浦区北京东路668号赛格电子市场2A25室</p>
      <div class="map_area">
        <baidu-map
          class="map"
          @ready="init"
          :center="mapData.center"
          :zoom="mapData.zoom"
        >
          <bm-marker :position="markLocation" @click="checkDetail" >
            <bm-info-window :title="showTitle" :show="showFlag"></bm-info-window>
          </bm-marker>
        </baidu-map>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactUsPage",
  data() {
        return {
          markLocation: {
            lng: 121.484344, lat: 31.245008
          },
          showTitle: "上海特浩电子科技有限公司",
          showFlag: false,
            //地图数据
            mapData: {
                //中心坐标
                center: { lng: 0, lat: 0 },
                //缩放级别
                zoom: 12
            },
            //BMap类
            BMap: null,
            //地图对象
            map: null
        }
    },
  methods: {
    checkDetail() {
      this.showFlag = !this.showFlag
    },
    init({ BMap, map }) {
      //保存百度地图类
      this.BMap = BMap;
      //保存地图对象
      this.map = map;
      // let xy = coordinateTrans.WGS84toBD([114.35567, 30.52922]);
      let point = new BMap.Point(121.484335,31.244873); //设置城市经纬度坐标
      map.centerAndZoom(point, 19); //
      map.setCurrentCity("上海"); // 设置地图显示的城市 此项是必须设置的
      map.enableScrollWheelZoom(true); //鼠标滚动缩放
      // map.setMapStyleV2({
      //   styleId: "6cd73bb20f7881b2089fafef35707723",
      // });
    },
    goToQQ() {
      window.open(
        "http://wpa.qq.com/msgrd?v=3&uin=3002947144&site=qq&menu=yes",
        "_blank"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.mainBox {
  font-size: 1.4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(70vh);
  min-height: 80vh;


  .goToQQ {
    cursor: pointer;
  }

  .map_area {
    width: 80%;
    height: 50rem;

    .map {
      width: 100%;
      height: 40rem;
    }
  }

  .flexbox1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    background-color: #fff;
    min-height: 80vh;

    .warpper_contactBox {
      width: 80%;
      padding: 0px 10%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
      margin-bottom: 2rem;

      .arCodeArea {
        word-break: break-all;
        border-width: 0.2rem;
        border-style: solid;
        border-color: rgb(255, 255, 255);
        background-color: rgb(242, 242, 242);
        border-radius: 5%;
        width: 16.2rem;
        display: flex;
        min-height: 18rem;
        flex-flow: column;
        align-items: center;
        overflow: hidden;

        .arcode {
          width: 14rem;
          margin-top: 0.5rem;
        }

        p {
          margin: 0.5rem 0;
        }
      }

      .iconArea {
        word-break: break-all;
        border-width: 0.2rem;
        border-style: solid;
        border-color: rgb(255, 255, 255);
        background-color: rgb(242, 242, 242);
        border-radius: 5%;
        width: 12rem;
        display: flex;
        min-height: 20rem;
        flex-flow: column;
        align-items: center;

        p {
          word-break: break-all;
        }

        .icon {
          margin-top: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 4.5rem;
          height: 4.5rem;
          border-radius: 50%;
          background-color: #0283c9;
          margin-bottom: 2rem;

          img {
            width: 3.2rem;
          }
        }
      }
    }

    .divider {
      border-bottom: 0.1rem dashed #ddd;
      margin-bottom: 2.5rem;
      word-spacing: -0.15rem;
      margin-top: 2rem;
      width: 100%;
    }

    .title1 {
      margin-top: 5rem;
      font-size: 3.2rem;
      font-weight: 400;

      span {
        font-weight: 600;
        color: #0283c9;
      }
    }

    .title2 {
      margin-top: 0px;
      margin-bottom: 1rem;
    }

    .content_Contact {
      padding: 10px 10%;
      text-indent: 2em;
      line-height: 5em;

      .phoneNum {
        line-height: 5rem;

        span {
          color: #0283c9;
          font-weight: 600;
          font-size: 2.4rem;
        }
      }
    }
  }
}
</style>