var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mainBox"},[_c('div',{staticClass:"flexbox1"},[_vm._m(0),_vm._m(1),_c('p',{staticClass:"divider"}),_c('div',{staticClass:"warpper_contactBox"},[_c('div',{staticClass:"iconArea"},[_c('div',{staticClass:"icon"},[_c('el-image',{staticStyle:{"width":"3.2rem"},attrs:{"src":require('@/assets/img/zuoji.png'),"fit":"fill","alt":"专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309","lazy":""}})],1),_vm._m(2),_c('p',[_vm._v("021-61032309")])]),_c('div',{staticClass:"iconArea"},[_c('div',{staticClass:"icon"},[_c('el-image',{staticStyle:{"width":"3.2rem"},attrs:{"src":require('@/assets/img/phone.png'),"fit":"fill","alt":"专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309","lazy":""}})],1),_vm._m(3),_c('p',[_vm._v("13501645968")])]),_c('div',{staticClass:"iconArea goToQQ",on:{"click":_vm.goToQQ}},[_c('div',{staticClass:"icon"},[_c('el-image',{staticStyle:{"width":"3.2rem"},attrs:{"src":require('@/assets/img/qq1.png'),"fit":"fill","alt":"专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309","lazy":""}})],1),_vm._m(4),_c('p',[_vm._v("3002947144")])]),_c('div',{staticClass:"arCodeArea"},[_c('el-image',{staticStyle:{"width":"14rem","margin-top":"0.5rem"},attrs:{"src":require('@/assets/img/vxVrCode.png'),"fit":"fill","alt":"专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309","lazy":""}}),_c('p',[_vm._v("微信扫码")])],1)]),_c('p',{staticClass:"divider"}),_vm._m(5),_c('p',[_vm._v("上海市黄浦区北京东路668号赛格电子市场2A25室")]),_c('div',{staticClass:"map_area"},[_c('baidu-map',{staticClass:"map",attrs:{"center":_vm.mapData.center,"zoom":_vm.mapData.zoom},on:{"ready":_vm.init}},[_c('bm-marker',{attrs:{"position":_vm.markLocation},on:{"click":_vm.checkDetail}},[_c('bm-info-window',{attrs:{"title":_vm.showTitle,"show":_vm.showFlag}})],1)],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"title1"},[_c('span',[_vm._v("联系")]),_vm._v("特浩")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_Contact thBorder"},[_c('p',[_vm._v(" 特浩电子前身为深圳美隆电子上海分公司，于2018年正式成立更名为上海特浩电子科技有限公司.成立以来一直与国内电子元器件代理商以及原厂保持紧密合作，致力打造成为国内电子元器件供应优质服务商！ ")]),_c('p',[_vm._v(" 主营贴片电阻电容，二三极管及周边配套电子元器件，专注提升自身发展策略，为客户提供更优质的服务 ")]),_c('p',[_vm._v(" 目前精供的品牌有：美隆SUP，长电/长晶JCET，美键达MJDA，公司常备大量现货，在深圳、上海均建立了大型仓储，主要以现货形式销售，产品型号齐全拥有完整的型号库存及优秀的销售及售后团队。 ")]),_c('p',{staticClass:"phoneNum"},[_vm._v("欢迎新老客户合作咨询："),_c('span',[_vm._v("021-61032309")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("电话号码")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("手机联系")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("QQ联系")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"title1 title2"},[_vm._v("特浩"),_c('span',[_vm._v("位置")])])
}]

export { render, staticRenderFns }