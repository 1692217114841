var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper_content"},[_c('div',[_vm._m(0),_c('ul',[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('li'),_c('li',{staticStyle:{"text-align":"right","color":"#0283c9"}},[_c('span',{staticClass:"moreInfo",on:{"click":_vm.goInfo}},[_vm._v("更多资讯")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"th_main_title"},[_c('span',[_vm._v("特浩资讯")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"btn"},[_c('a',{attrs:{"href":"/tehaoInfoDetail?id=2&title=%E8%B4%B4%E7%89%87%E7%94%B5%E9%98%BB%E6%80%A7%E8%83%BD%E6%8C%87%E6%A0%87"}},[_c('span',{staticClass:"infoTop"},[_vm._v("贴片电阻性能指标是什么？")]),_c('p',{staticClass:"infoMain"},[_vm._v(" 1、体积：表示电阻的长*宽*高;2、阻值：表示这个电阻对电流流动阻挡力的大小;3、功率：表示电阻在时间单位内所做的功;4、误差：表示电阻的精度温漂：表示电阻受温度影响的大小 ;5、温漂：表示电阻受温度影响的大小... ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"btn"},[_c('a',{attrs:{"href":"/tehaoInfoDetail?id=3&title=%E8%B4%B4%E7%89%87%E7%94%B5%E9%98%BB%E7%9F%A5%E8%AF%86%E5%88%86%E4%BA%AB"}},[_c('span',{staticClass:"infoTop"},[_vm._v("了解电阻的定义和制作流程")]),_c('p',{staticClass:"infoMain"},[_vm._v(" 在电路中限制电流或将电能转换为热能的电子元器件；电阻（Resistance，通常用“R”表示），在物理学中表示导体对电流阻碍作用的大小。导体的电阻越大，表示导体对电流的阻碍作用越大。电阻的主要物理特征是变电能为热能，也可说它是一个耗能元件，电流经过它就产生内... ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"btn"},[_c('a',{attrs:{"href":"/tehaoInfoDetail?id=4&title=贴片电容知识分享"}},[_c('span',{staticClass:"infoTop"},[_vm._v("电容是什么？")]),_c('p',{staticClass:"infoMain"},[_vm._v(" 贴片电容是一种电容材质。贴片电容全称为：多层（积层，叠层）片式陶瓷电容器，也称为贴片电容，片容。贴片电容有两种表示方法，一种是英寸单位来表示，一种是毫米单位来表示。 电容器，顾名思义，是“装电的容器”，是一种容纳电荷的器件。英文名称：capacitor。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"btn"},[_c('a',{attrs:{"href":"tehaoInfoDetail?id=6&title=%E8%B4%B4%E7%89%87%E7%94%B5%E9%98%BB%E5%82%A8%E5%AD%98%E4%BA%A7%E5%93%81%E7%8E%AF%E5%A2%83"}},[_c('span',{staticClass:"infoTop"},[_vm._v("贴片电阻储存产品环境？")]),_c('p',{staticClass:"infoMain"},[_vm._v(" 贴片电阻电容的保存和运输要求: "),_c('br'),_vm._v(" 非裸包装(说明：裸包装是指物料的原包装被拆分后的再存储。) 存储期限： 出货起1年 1、防潮(说明：不要将贴片电容储存在高温和湿度高的地方，储存环境) ①、 管控仓储的温度要求(15 ℃~ 35 ℃)、湿度要求(25 %~ 75 %) ... ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"btn"},[_c('a',{attrs:{"href":"/tehaoInfoDetail?id=7&title=%E4%BB%80%E4%B9%88%E6%98%AF%E8%B4%B4%E7%89%87%E7%94%B5%E6%84%9F%EF%BC%9F"}},[_c('span',{staticClass:"infoTop"},[_vm._v("什么是贴片电感？")]),_c('p',{staticClass:"infoMain"},[_vm._v(" 贴片电感(Chipinductors)，又称功率电感、大电流电感和表面安装高功率电感。它具有小型化、高质量、高能储存和低电阻的特点。一般来说，电子线路中的电感是中空线圈或带磁芯的线圈，只能通过较小的电流承受较低的电压。功率电感也包括中空线圈和带磁芯的线... ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"btn"},[_c('a',{attrs:{"href":"/tehaoInfoDetail?id=8&title=%E9%92%BD%E7%94%B5%E5%AE%B9%E4%BD%9C%E7%94%A8%E5%8F%8A%E4%BD%BF%E7%94%A8%E6%B3%A8%E6%84%8F%E4%BA%8B%E9%A1%B9"}},[_c('span',{staticClass:"infoTop"},[_vm._v("贴片电阻性能指标")]),_c('p',{staticClass:"infoMain"},[_vm._v(" 与一般电容相比，贴片钽电容的性能优异，体积小，又可以达到较大的电容量，在电源滤波、交流旁路等方面，稍有竞争产品。 钽电解电容器具有储藏电量、进行充放电等性能，主要应用于滤波、能量贮存与转换，记号旁路，耦合与退耦以及作时间常数元件等。 贴片钽电容主... ")])])])
}]

export { render, staticRenderFns }