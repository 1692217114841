<template>
  <div class="wrapper_content">
    <div>
      <p class="th_main_title"><span>特浩资讯</span></p>
      <ul>
        <li class="btn">
          <a href="/tehaoInfoDetail?id=2&title=%E8%B4%B4%E7%89%87%E7%94%B5%E9%98%BB%E6%80%A7%E8%83%BD%E6%8C%87%E6%A0%87">
            <span class="infoTop">贴片电阻性能指标是什么？</span>
            <p class="infoMain">
              1、体积：表示电阻的长*宽*高;2、阻值：表示这个电阻对电流流动阻挡力的大小;3、功率：表示电阻在时间单位内所做的功;4、误差：表示电阻的精度温漂：表示电阻受温度影响的大小
;5、温漂：表示电阻受温度影响的大小...
            </p>
          </a>
        </li>
        <li class="btn">
          <a href="/tehaoInfoDetail?id=3&title=%E8%B4%B4%E7%89%87%E7%94%B5%E9%98%BB%E7%9F%A5%E8%AF%86%E5%88%86%E4%BA%AB">
            <span class="infoTop">了解电阻的定义和制作流程</span>
            <p class="infoMain">
              在电路中限制电流或将电能转换为热能的电子元器件；电阻（Resistance，通常用“R”表示），在物理学中表示导体对电流阻碍作用的大小。导体的电阻越大，表示导体对电流的阻碍作用越大。电阻的主要物理特征是变电能为热能，也可说它是一个耗能元件，电流经过它就产生内...
            </p>
          </a>
        </li>
        <li class="btn">
          <a href="/tehaoInfoDetail?id=4&title=贴片电容知识分享">
            <span class="infoTop">电容是什么？</span>
            <p class="infoMain">
              贴片电容是一种电容材质。贴片电容全称为：多层（积层，叠层）片式陶瓷电容器，也称为贴片电容，片容。贴片电容有两种表示方法，一种是英寸单位来表示，一种是毫米单位来表示。

电容器，顾名思义，是“装电的容器”，是一种容纳电荷的器件。英文名称：capacitor。
            </p>
          </a>
        </li>
        <li class="btn">
          <a href="tehaoInfoDetail?id=6&title=%E8%B4%B4%E7%89%87%E7%94%B5%E9%98%BB%E5%82%A8%E5%AD%98%E4%BA%A7%E5%93%81%E7%8E%AF%E5%A2%83">
            <span class="infoTop">贴片电阻储存产品环境？</span>
            <p class="infoMain">
              贴片电阻电容的保存和运输要求: <br>
非裸包装(说明：裸包装是指物料的原包装被拆分后的再存储。)

存储期限：

出货起1年

1、防潮(说明：不要将贴片电容储存在高温和湿度高的地方，储存环境)

①、 管控仓储的温度要求(15 ℃~ 35 ℃)、湿度要求(25 %~ 75 %)

...
            </p>
          </a>
        </li>
        <li class="btn">
          <a href="/tehaoInfoDetail?id=7&title=%E4%BB%80%E4%B9%88%E6%98%AF%E8%B4%B4%E7%89%87%E7%94%B5%E6%84%9F%EF%BC%9F">
            <span class="infoTop">什么是贴片电感？</span>
            <p class="infoMain">
              贴片电感(Chipinductors)，又称功率电感、大电流电感和表面安装高功率电感。它具有小型化、高质量、高能储存和低电阻的特点。一般来说，电子线路中的电感是中空线圈或带磁芯的线圈，只能通过较小的电流承受较低的电压。功率电感也包括中空线圈和带磁芯的线...
            </p>
          </a>
        </li>
        <li class="btn">
          <a href="/tehaoInfoDetail?id=8&title=%E9%92%BD%E7%94%B5%E5%AE%B9%E4%BD%9C%E7%94%A8%E5%8F%8A%E4%BD%BF%E7%94%A8%E6%B3%A8%E6%84%8F%E4%BA%8B%E9%A1%B9">
            <span class="infoTop">贴片电阻性能指标</span>
            <p class="infoMain">
              与一般电容相比，贴片钽电容的性能优异，体积小，又可以达到较大的电容量，在电源滤波、交流旁路等方面，稍有竞争产品。

钽电解电容器具有储藏电量、进行充放电等性能，主要应用于滤波、能量贮存与转换，记号旁路，耦合与退耦以及作时间常数元件等。

贴片钽电容主...
            </p>
          </a>
        </li>
        <li></li>
        <li style="text-align:right;color:#0283c9"><span class="moreInfo" @click="goInfo">更多资讯</span></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'chsoeTh',
  methods: {
    goInfo() {
      window.open(
        "http://itehao.com/tehaoInfo",
        "_blank"
      );
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  font-size: 1.7rem;
  background: transparent;
  border: none;
  padding: 1em 1.5em;
  color: #ffedd3;
  text-transform: uppercase;
  position: relative;
  transition: .5s ease;
}

.btn::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 0;
  background-color: #0283c9;
  transition: .5s ease;
}

.btn:hover {
  color: #1e1e2b;
  transition-delay: .5s;
}

.btn:hover::before {
  width: 100%;
}

.btn::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 0;
  width: 100%;
  background-color: #0283c9;
  transition: .4s ease;
  z-index: -1;
}

.btn:hover::after {
  height: 100%;
  transition-delay: 0.4s;
  color: aliceblue;
}

ul {
  display: block;
  padding: 0;
  margin: 0;
  list-style: none;
  justify-content: center;
  align-items: center;

  li {
    text-align: center;
    position: relative;
    display: inline-block;
    box-sizing: padding-box;
    margin: 2rem;
    vertical-align: top;
    width: 45%;
    box-sizing: border-box;

    a {
      text-decoration: none;
      display: inline-block;
      color: black;
      text-align: left;
      width: 100%;

      .infoTop {
        font-size: 2.8rem;
      }
    }

    .infoMain {
      margin-top: 5rem;
      text-indent: 2rem;
      color: #b39999;
      font-size: 2rem;
      padding-bottom: 20px;
    }
  }

  li:hover .infoTop {
    color: #0283c9;
  }
}

.moreInfo {
  cursor: pointer;
}
.moreInfo:hover {
  color: #0283c9
}

@media (max-width: 770px) {  
  .btn {
    width: 100%;
  }
}
</style>