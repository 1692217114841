<template>
  <div class="topMenu">
    <div class="logoArea" @click="goHome">
      <el-image
        :src="require('@/assets/img/tehaologo2.png')"
        class="logo"
        fit="scale-down"
        alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
      />
    </div>
    <div class="menu_item" style="float:right">
      <router-link to="/" exact active-class="item_active" class="item">关于特浩</router-link>
      <router-link to="/thProduct" active-class="item_active" :class="$route.name==='thProductDetail' ? 'item_active item' : 'item'">特浩产品</router-link>
      <router-link to="/tehaoInfo" active-class="item_active" :class="$route.name==='tehaoInfoDetail' ? 'item_active item' : 'item'">特浩资讯</router-link>
      <router-link to="/contactUs" active-class="item_active" class="item">联系特浩</router-link>
    </div>
    <!-- <div class="slo">
      <i>贴片电阻电容-二三极管优质供应商&emsp;欢迎致电：021-61032309 </i>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "topMenu",
  data() {
    return {};
  },
  // created() {
  //   console.log(this.$route)
  // },
  computed: {
    activeIndex() {
      return this.$route.name
    }
  },
  methods: {
    goHome() {
      this.$router.push({path: '/'})
    }
  },
};
</script>

<style lang="scss" scoped>
.topMenu {
  width: 100%;
  min-height: 62px;
  background-color: #fff;
  overflow: hidden;
  box-sizing: border-box;
  z-index: 2;


  .logoArea {
    float: left;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .logo {
      width: 220px;
      cursor: pointer;
    }
  }
  .slo {
    display: inline-block;
    font-size: 2.2rem;
    padding-left: 10rem;
    color: rgb(255, 208, 75);
    line-height: 60px;
    white-space: nowrap;
  }

  .menu_item {
    color: black;
    min-width:80px;
    font-size: 1.8rem;
    height: 60px;
    background-color: #fff;
    display: flex;
    align-items: center;
    display: inline-block;
    box-sizing: border-box;

    .item {
      text-decoration: none;
      color: black;
      display: inline-flex;
      min-width:80px;
      height: 100%;
      align-items: center;
      justify-content: center;
      transition: all ;
      padding: 0px 20px;
    }

    .item:hover {
      background-color: rgb(124, 94, 94);
    }

    .item_active {
      color: rgb(255, 208, 75);
      border-bottom-color: rgb(255, 208, 75);
      border-bottom: 2px solid rgb(255, 208, 75);
      background-color: rgb(124, 94, 94);
    }
  }
  @media (max-width: 520px) {  
    .menu_item {
      font-size: 12px;
      .item {
        padding: 0px 0px;
      }
    }
  }
}
</style>