import Vue from 'vue'
import App from './App.vue'
import router from './router' 

Vue.config.productionTip = false

// import Cookies from 'js-cookie'
// import Element from 'element-ui'
// 按需引入
import { Carousel,CarouselItem,Menu,MenuItem,Pagination,PageHeader,Image,Divider } from 'element-ui'
import 'animate.css';
import './styles/element-variables.scss'
import './styles/container.scss'

import './changeFontsize.js'

import BaiduMap from 'vue-baidu-map'

// import VueLazyload from "vue-lazyload"; // 图片懒加载
// Vue.use(VueLazyload)

// elementui组件按需引入
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Pagination);
Vue.use(PageHeader);
Vue.use(Image);
Vue.use(Divider);

// Vue.use(Element, {
//   size: Cookies.get('size') || 'medium' // set element-ui default size
// })

Vue.use(BaiduMap, {
  ak: 'RZhHKWVgOzOr5azLGxfb9FiVhvEV7N9G'  //  在此输入你自己的百度地图ak
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
