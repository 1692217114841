import { render, staticRenderFns } from "./thInfomation.vue?vue&type=template&id=92c8ab46&scoped=true&"
import script from "./thInfomation.vue?vue&type=script&lang=js&"
export * from "./thInfomation.vue?vue&type=script&lang=js&"
import style0 from "./thInfomation.vue?vue&type=style&index=0&id=92c8ab46&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92c8ab46",
  null
  
)

export default component.exports