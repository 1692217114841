<template>
  <div>
    <img
      src="@/assets/img/banner2.png"
      style="width: 100%;"
      alt=""
    />
    <div class="wrapper_content">
      <div class="wrapper-topdes">
        <div class="left-img">
          <el-image
            class="proPic"
            :src="clickObj.img"
            fit="contain"
            alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
            lazy
          />
        </div>
        <div class="right-des">
          <p class="title">{{ clickObj.title }}</p>
          <el-divider />
          <div class="desContent">
            <p v-if="clickObj.type">产品型号:&emsp;{{ clickObj.type }}</p>
            <p v-if="clickObj.size">封装尺寸:&emsp;{{ clickObj.size }}</p>
            <p v-if="clickObj.value">阻值:&emsp;{{ clickObj.value }}</p>
            <p v-if="clickObj.power">功率:&emsp;{{ clickObj.power }}</p>
            <p v-if="clickObj.precision">精度:&emsp;{{ clickObj.precision }}</p>
            <p v-if="clickObj.containValue">容值:&emsp;{{ clickObj.containValue }}</p>
            <p v-if="clickObj.material">材质:&emsp;{{ clickObj.material }}</p>
            <p v-if="clickObj.thickness">厚度:&emsp;{{ clickObj.thickness }}</p>
            <p v-if="clickObj.voltage">电压:&emsp;{{ clickObj.voltage }}</p>
            <button class="button" @click="goQQ">立即咨询</button>
            <strong style="margin-left:2rem">联系我们: <em style="color:#0083ca">021-61032309</em></strong>
          </div>
        </div>
      </div>
      <div class="switchMenu">
        <span :class="activeMenu === 'chose' ? 'activeSpan' : ''" @click="changeMenu('chose')">选择特浩</span>
        <span :class="activeMenu === 'contact' ? 'activeSpan' : ''" @click="changeMenu('contact')">联系特浩</span>
      </div>
    </div>
    <div style="fontSize:1.6rem">
      <choseTh v-if="activeMenu === 'chose'" />
      <contactUsPage v-if="activeMenu === 'contact'" />
    </div>
  </div>
</template>

<script>
import choseTh from '@/components/contentComponents/choseTh.vue'
import contactUsPage from '@/pages/contactUsPage.vue'

export default {
  data() {
    return {
      clickObj: null,
      activeMenu: 'chose' // chose 选择特浩 contact 联系特浩
    }
  },
  components: { choseTh, contactUsPage },
  methods: {
    getObj() {
      this.clickObj = JSON.parse(sessionStorage.getItem('clickObj'))
      console.log('obj,', this.clickObj)
    },
    goQQ() {
      window.open(
        "http://wpa.qq.com/msgrd?v=3&uin=3002947144&site=qq&menu=yes",
        "_blank"
      );
    },
    changeMenu(menu) {
      this.activeMenu = menu
    }
  },
  created() {
    this.getObj()
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-divider--horizontal {
  margin-bottom: 1rem;
}
.activeSpan {
  background-color: #0083ca;
}
.switchMenu {
  background-color: #8c8c8c;
  cursor: pointer;
  width: 100%;
  margin: 2rem 0; 
  line-height: 8rem;
  text-align: center;
  color: #fff;
  font-size: 1.7rem;

  span {
    width:50%;
    display: inline-block;
  }
}
.wrapper-topdes {
  width: 100%;
  position: relative;
  display: flex;

  .left-img {
    float: left;
    width: 50rem;
    overflow: hidden;
    margin-right: 10rem;
  }

  .right-des {
    float: left;
    width: 60rem;

    .title {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 2.2rem;
      text-align: left;
      font-weight: 700;
    }

    .desContent {
      text-align: left;
      font-size: 2rem;
    }
  }
}

.button {
 display: inline-block;
 padding: 1.2rem 2.4rem;
 border: .1rem solid #0083ca;
 border-radius: 10px;
 transition: all 0.2s ease-in;
 position: relative;
 overflow: hidden;
 font-size: 1.9rem;
 color: black;
 z-index: 1;
}

.button:before {
 content: "";
 position: absolute;
 left: 50%;
 transform: translateX(-50%) scaleY(1) scaleX(1.25);
 top: 100%;
 width: 140%;
 height: 180%;
 background-color: rgba(0, 0, 0, 0.05);
 border-radius: 50%;
 display: block;
 transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
 z-index: -1;
}

.button:after {
 content: "";
 position: absolute;
 left: 55%;
 transform: translateX(-50%) scaleY(1) scaleX(1.45);
 top: 180%;
 width: 160%;
 height: 190%;
 background-color: #39bda7;
 border-radius: 50%;
 display: block;
 transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
 z-index: -1;
}

.button:hover {
 color: #ffffff;
 border: 1px solid #39bda7;
 cursor: pointer;
}

.button:hover:before {
 top: -35%;
 background-color: #39bda7;
 transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

.button:hover:after {
 top: -45%;
 background-color: #39bda7;
 transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}
@media (max-width: 576px) {  
  .left-img {
    display: none;
  }
}
</style>