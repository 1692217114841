<template>
  <div class="mainBox">
    <div class="flexbox1">
      <el-carousel :interval="4000" type="card" style="width:100%;" height="30vw">
        <el-carousel-item>
          <el-image
            :src="require('@/assets/img/main2.jpg')"
            fit="scale-down"
            alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
          />
        </el-carousel-item>
        <el-carousel-item>
          <el-image
            :src="require('@/assets/img/main3.jpg')"
            fit="scale-down"
            alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
          />
        </el-carousel-item>
        <el-carousel-item>
          <el-image
            :src="require('@/assets/img/main4.jpg')"
            fit="scale-down"
            alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
          />
        </el-carousel-item>
      </el-carousel>
      <div class="wrapper_content">
        <p class="th_main_title animate__animated animate__backInLeft">特浩<span>资讯</span></p>
      </div>
      <div v-if="currentPage === 1" style="width: 100%;display: flex;flex-direction: column;align-items: center;">
        <div v-for="info in InfoList" :key="info.id" @click="showInfoDetail(info)" class="infoBox">
          <div class="detailImg">
            <el-image
              style="width: 100%;height: 100%;min-height: 120px;"
              :src="info.imgurl"
              fit="fill"
              alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
              lazy
            />
          </div>
          <div style="float:left,display:inline-block">
            <h3>{{ info.title }}</h3>
            <p>{{ info.summary }}</p>
          </div>
        </div>
      </div>
      <div v-if="currentPage === 2" style="width: 100%;display: flex;flex-direction: column;align-items: center;">
        <div v-for="info in InfoListP2" :key="info.id" @click="showInfoDetail(info)" class="infoBox">
          <div class="detailImg">
            <el-image
              style="width: 100%;height: 100%;min-height: 120px;"
              :src="info.imgurl"
              fit="fill"
              alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
              lazy
            />
          </div>
          <div style="float:left,display:inline-block">
            <h3>{{ info.title }}</h3>
            <p>{{ info.summary }}</p>
          </div>
        </div>
      </div>
      <div v-if="currentPage === 3" style="width: 100%;display: flex;flex-direction: column;align-items: center;">
        <div v-for="info in InfoListP3" :key="info.id" @click="showInfoDetail(info)" class="infoBox">
          <div class="detailImg">
            <el-image
              style="width: 100%;height: 100%;min-height: 120px;"
              :src="info.imgurl"
              fit="fill"
              alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
              lazy
            />
          </div>
          <div style="float:left,display:inline-block">
            <h3>{{ info.title }}</h3>
            <p>{{ info.summary }}</p>
          </div>
        </div>
      </div>
      <el-pagination
        class="pagination"
        :page-size="4"
        :total="totalCount"
        :current-page="currentPage"
        layout="prev, pager, next"
        @current-change="currentClick"
        @prev-click="currentClick"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: "tehaoInfo",
  data() {
    return {
      currentPage: 1,
      totalCount: 9,
      showDetail: false,
      clickObj: {}, // 传入详情组件的点击对象
      InfoList: [
        {
          id: 1,
          title: '合金贴片电阻',
          summary: '合金电阻作为电流载体，低阻值，高精密，低温度系数，耐较高的脉冲电流，大功率等特点阻值精准，温度稳定性，产品的安全性，稳定性明显高于普通的陶瓷电阻。同时金属的导热性强也是一大优势。高导热材质可大幅降低电路板上的散热面积,特别...',
          imgurl: require('@/assets/img/dz.png')
        },{
          id: 2,
          title: '贴片电阻性能指标',
          summary: '电阻的五项性能指标.1、体积：表示电阻的长*宽*高。2、阻值：表示这个电阻对电流流动阻挡力的大小。3、功率：表示电阻在时间单位内所做的功。4、误差：表示电阻的精度温漂：表示电阻受温度影响的大小。5、温漂：表示电阻受温度影响的大小...',
          imgurl: require('@/assets/img/th_application2.jpg')
        },{
          id: 3,
          title: '贴片电阻知识分享',
          summary: '什么是电阻？在电路中限制电流或将电能转换为热能的电子元器件。电阻（Resistance，通常用“R”表示），在物理学中表示导体对电流阻碍作用的大小。导体的电阻越大，表示导体对电流的阻碍作用越大。电阻的主要物理特征是变电能为热能，也可说...',
          imgurl: require('@/assets/img/dz.png')
        },{
          id: 4,
          title: '贴片电容知识分享',
          summary: '什么是电容？贴片电容是一种电容材质。贴片电容全称为：多层（积层，叠层）片式陶瓷电容器，也称为贴片电容，片容。贴片电容有两种表示方法，一种是英寸单位来表示，一种是毫米单位来表示。电容器，顾名思义，是装电的...',
          imgurl: require('@/assets/img/dr.png')
        }
      ],
      InfoListP2: [
        {
          id: 5,
          title: '贴片电阻规格命名方法',
          summary: '贴片电阻规格命名方法、分类、尺寸功率信息与通信有工程科技和专业资料。内地度贴片电阻的命名方法:182是5%精度阻值表示法：前两位数表示有效数字，第三位表示有多少个零，基本单位是Ω，182=1800Ω=1.8KΩ...',
          imgurl: require('@/assets/img/dz.png')
        },{
          id: 6,
          title: '贴片电阻储存产品环境',
          summary: '电阻的五项性能指标.1、体积：表示电阻的长*宽*高。2、阻值：表示这个电阻对电流流动阻挡力的大小。3、功率：表示电阻在时间单位内所做的功。4、误差：表示电阻的精度温漂：表示电阻受温度影响的大小。5、温漂：表示电阻受温度影响的大小...',
          imgurl: require('@/assets/img/th_application2.jpg')
        },{
          id: 7,
          title: '什么是贴片电感？',
          summary: '贴片电感(Chipinductors)，又称功率电感、大电流电感和表面安装高功率电感。它具有小型化、高质量、高能储存和低电阻的特点。一般来说，电子线路中的电感是中空线圈或带磁芯的线圈，只能通过较小的电流承受较低的电压。功率电感也包括中...',
          imgurl: require('@/assets/img/dg.png')
        },{
          id: 8,
          title: '钽电容作用及使用注意事项',
          summary: '与一般电容相比，贴片钽电容的性能优异，体积小，又可以达到较大的电容量，在电源滤波、交流旁路等方面，稍有竞争产品。钽电解电容器具有储藏电量、进行充放电等性能，主要应用于滤波、能量贮存与转换，记号旁路，耦合...',
          imgurl: require('@/assets/img/ddr.jpg')
        }
      ],
      InfoListP3: [
        {
          id: 9,
          title: '贴片电阻的精度有哪些?',
          summary: '说到贴片电阻，大多数人会关注它的阻值，功率，耐压等，会把精度给忘掉，但其实精度也是非常重要的。以智能家电为例：贴片电阻精度的高低会直接影响电器设备的性能和效果，主要原因在于电阻的精度影响着对电流和电压的调控能力，对于智能家电的效...',
          imgurl: require('@/assets/img/dzjd.jpg')
        },{
          id: 10,
          title: '金属膜电阻和碳膜电阻的区别',
          summary: '贴片电阻又名片式固定电阻器，是金属玻璃釉电阻器中的一种。是将金属粉和玻璃釉粉混合，采用丝网印刷法印在基板上制成的电阻器。耐潮湿和高温， 温度系数小。可大大节约电路空间成本，使设计更精细化...',
          imgurl: require('@/assets/img/dzjd.jpg')
        }
      ]
    }
  },
  methods: {
    currentClick(clickPage) {
      this.currentPage = clickPage
      window.scrollTo(0,0)
    },
    showInfoDetail(info) {
      // this.clickObj = JSON.parse(JSON.stringify(info))
      // this.showDetail = true
      this.$router.push({ path:'/tehaoInfoDetail', query:{ 'id': info.id, 'title': info.title } })
      window.scrollTo(0,0)
    },
    changeFlag() {
      this.showDetail = false
      console.log('current:', this.currentPage)
    }
  },
};
</script>

<style lang="scss" scoped>
.mainBox {
  font-size: 1.4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 80vh;
  box-sizing: border-box;

  .pagination {
    margin-top: 10rem;
    padding-bottom: 5rem;
  }

  .flexbox1 {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    min-width: 230px;
    background-color: #fff;
    padding-bottom: 20rem;

    .carouselImg {
      width: 100%;
      height: 100%;
    }

    .detailImg {
      width: 150px;
      float: left;
      margin-top: 2rem;
      margin-right: 1rem;
    }

    .infoBox {
      width: 90%;
      min-height: 25rem;
      border: 2px solid #ddd;
      transition: all 0.5s ease;
      box-sizing: border-box;
      padding: 5rem;

      h3 {
        color: #0283c9;
        text-indent: 2em;
      }

      p {
        width: 100%;
        text-indent: 2em;
        line-height: 5rem;
      }
    }
    .infoBox:hover {
      box-shadow: 1rem 1rem 0.5rem #888888;
      cursor: pointer;
      // margin-bottom: 1rem;
      transform: translateY(-1rem);
      background-color: rgb(244, 240, 240);
    }
  }
  .flexbox1:nth-child(2) {
    border-top: 1px solid #ddd;
  }
}

@media (max-width: 576px) {  
  .detailImg {
    display: none;
  }
}
</style>