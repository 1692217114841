<template>
  <div class="footer">
    <div class="wrapper">
      <div class="ft_menu">
        <dl class="AboutTh">
          <dt><span class="ft_title1">特浩</span></dt>
          <dd @click="goThere('/')">关于特浩</dd>
          <dd @click="goThere('/thProduct')">特浩产品</dd>
          <dd @click="goThere('/tehaoInfo')">特浩资讯</dd>
          <dd @click="goThere('/contactUs')">联系我们</dd>
        </dl>
      </div>
      <div class="ft_info">
        <dl>
          <dt><span class="ft_title1">联系特浩</span></dt>
          <dd>全国服务热线：<em>021-61032309</em></dd>
          <dd>24小时服务专线：<em>13501645968</em></dd>
          <dd>公司地址：上海市黄浦区北京东路666号赛格电子市场G区2A25室</dd>
          <dd><a href="https://beian.miit.gov.cn" class="goBeian" target="_blank">备案号：沪ICP备2022031101号</a></dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'myFooter',
  methods: {
    goThere(where) {
      console.log(this.$route)
      let nowPath = this.$route.path
      if (where !== nowPath) {
        this.$router.replace(where)
        window.scrollTo(0,0)
      } else {
        window.scrollTo(0,0)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  min-height: 36.2rem;
  width: 100%;
  padding: 6rem 0;
  overflow: hidden;
  background: #212121;
  box-sizing: border-box;
}

.goBeian {
  color: #FFF;
  text-decoration: none;
}

.goBeian:hover {
  text-decoration: underline;
}

.wrapper {
  margin: 0 auto;
  width: 70%;

  .ft_menu {
    float: left;
    width: 40%;
    overflow: hidden;
    font-size: 1.4rem;
  }

  .ft-menu dl {
    float: left;
    width: 50%;
    margin-top: 0px;
    
  }
  
  .ft-menu dt {
    height: 4rem;
    line-height: 4rem;
    font-size: 2rem;
    font-weight: bold;
  }
  .ft_title1 {
    font-size: 2rem;
    font-weight: 600;
    color: #fff;
    transition: all 0.5s;
  } 

  .ft_menu dd {
    padding-top: 2.2rem;
    overflow: hidden;
    margin-left: 0px;
    color: #FFF;
  }

  .ft_info {
    float: right;
    width: 40%;
    text-align: right;
    font-size: 1.4rem;
  }

  .ft_info dl {
    float: right;
    width: 50%;
    margin-top: 0px;
    
  }

  .ft_info dt {
    height: 4rem;
    line-height: 4rem;
    font-size: 2rem;
    font-weight: bold;
  }

  .ft_info dd {
    padding-top: 2.2rem;
    float: right;
    margin-left: 0px;
    width: 40rem;
    color: #FFF;
  }

}
.AboutTh {
  dd {
    cursor: pointer;
    transition: all 0.5s ease;
  }

  dd:hover {
    font-weight: 700;
  }
}
</style>