<template>
  <div class="wrapper_content">
    <p class="th_main_title"><span>应用</span>领域</p>
    <div class="th_pro">
      <ul>
        <li class="applicationLi">
          <el-image
            class="imgtp"
            :src="require('@/assets/img/application_xyj.png')"
            fit="scale-down"
            alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
            lazy
          />
          <p class="th_pro_title">家电</p>
        </li>
        <li class="applicationLi">
          <el-image
            class="imgtp"
            :src="require('@/assets/img/application_dy.png')"
            fit="scale-down"
            alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
            lazy
          />
          <p class="th_pro_title">电源</p>
        </li>
        <li class="applicationLi">
          <el-image
            class="imgtp"
            :src="require('@/assets/img/application_zm.png')"
            fit="scale-down"
            alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
            lazy
          />
          <p class="th_pro_title">照明</p>
        </li>
        <li class="applicationLi">
          <el-image
            class="imgtp"
            :src="require('@/assets/img/application_dzgj.png')"
            fit="scale-down"
            alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
            lazy
          />
          <p class="th_pro_title">电动工具</p>
        </li>
        <li class="applicationLi">
          <el-image
            class="imgtp"
            :src="require('@/assets/img/application_znyb.png')"
            fit="scale-down"
            alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
            lazy
          />
          <p class="th_pro_title">智能仪表</p>
        </li>
        <li class="applicationLi">
          <el-image
            class="imgtp"
            :src="require('@/assets/img/application_jk.png')"
            fit="scale-down"
            alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
            lazy
          />
          <p class="th_pro_title">安防监控</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
name: 'applicationArea'
}
</script>

<script>
var getLi = document.getElementsByClassName('applicationLi');
var maxH = 0
console.log(getLi)
for (let i=0; i<getLi.length;i++){
  if (maxH<getLi[i].offsetHeight) {
    maxH = getLi[i].offsetHeight
  }
  console.log(maxH)
}
console.log(getLi)
for (let i=0; i<getLi.length;i++){
  getLi[i].offsetHeight = maxH
}

</script>

<style lang="scss" scoped>
.th_pro {
  padding: 1rem 0;
  width: 100%;
  ul {
    display: block;
    padding: 0;
    margin: 0;
    list-style: none;
    width: 98%;

    li {
      text-align: center;
      position: relative;
      display: inline-block;
      width: 22%;
      margin: 2rem;
      min-width: 33rem;
      vertical-align: center;

      .th_pro_title {
        font-size: 2rem;
      }

      img {
        width: 100%;
        height: 100%;
        transition: all 0.5s ease;
      }

    }
  }
  /deep/ .el-image__inner{
    height: 200px;
  }

  .imgtp:hover+.show_word{
    opacity: 0.7;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease;

  }
}
</style>