<template>
<div class="contactUs">
  <ul>
    <li @click="goToQQ">
      <div class="word">
        点击联系&emsp;
      </div>
      <div class="icon">
        <el-image
          style="width: 3.2rem;height: 3.2rem;"
          :src="require('@/assets/img/qq1.png')"
          fit="scale-down"
          alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
        />
      </div>
    </li>
    <li @click="goToQQ">
      <div class="word phone">
        13501645968&emsp;&emsp;
      </div>
      <div class="icon">
        <el-image
          style="width: 3.2rem;height: 3.2rem;"
          :src="require('@/assets/img/phone.png')"
          fit="scale-down"
          alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
        />
      </div>
    </li>
    <li @click="goToQQ">
      <div class="word phone">
        021-61032309&emsp;&emsp;
      </div>
      <div class="icon">
        <el-image
          style="width: 3.2rem;height: 3.2rem;"
          :src="require('@/assets/img/tel.png')"
          fit="scale-down"
          alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
        />
      </div>
    </li>
    <li class="adcodeIcon" @click="goToQQ">
      <div class="icon">
        <el-image
          style="width: 3.2rem;height: 3.2rem;"
          :src="require('@/assets/img/arcode.png')"
          fit="scale-down"
          alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
        />
      </div>
    </li>
    <div class="arcode">
      <el-image
          style="width: 15rem;height: 15rem;"
          :src="require('@/assets/img/vxVrCode.png')"
          fit="scale-down"
          alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
        />
    </div>
  </ul>
</div>
</template>

<script>
export default {
  name: "contactUs",
  methods: {
    goToQQ() {
      window.open(
        "http://wpa.qq.com/msgrd?v=3&uin=3002947144&site=qq&menu=yes",
        "_blank"
      );
    },
  }
};
</script>

<style lang="scss" scoped>
.contactUs {
  position: fixed;
  right: 0;
  top: 35%;
  z-index: 2;
  .arcode {
    display: inline-block;
    vertical-align: bottom;
    background-color: #ccc;
    width: 15rem;
    height: 0rem;
    position: absolute;
    right: 6rem;
    top: 6rem;
    overflow: hidden;
    transition: all 0.5s ease;
  }
  .adcodeIcon:hover+.arcode {
    height: 15rem;
  }

  li {
    overflow: hidden;
    list-style: none;
    position: relative;
    vertical-align: center;
    margin: 0 !important;
    transition: all 0.5s ease;
    height: 5rem;
    box-sizing: border-box;

    .icon {
      background: #666;
      width: 5rem;
      height: 5rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      float: right;
      border-bottom: 0.1rem solid #555;
      position: relative;
      right: -0.1rem;
      transition: all 0.5s ease;
    }
    .word {
      align-items: center;
      justify-content: center;
      line-height: 5rem;
      vertical-align: top;
      border-bottom: 0.1rem solid #555;
      float: right;
      position: relative;
      background-color: #666;
      color: #fff;
      white-space: nowrap;
      max-width: 0px;
      font-size: 1.4rem;
      transition: all 0.8s ease;
    }
    
  }
  li:hover .icon {
    margin-bottom: 0.1rem;
    cursor: pointer;
    background-color: #0283c9;
  }
  li:hover .word {
    max-width: 150px;
    background-color: #0283c9;
    cursor: pointer;
    display: block;
  }
  li:hover .phone {
    max-width: 150px;
    background-color: #0283c9;
    cursor: pointer;
    display: block;
  }
  li:last-child {
    border-bottom: 0px;
  }
}
</style>