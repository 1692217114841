<template>
  <div class="contentBox">
    <div class="content">
      <div style="position:relative">
        <img src="@/assets/img/banner.jpg" style="width:100%;" alt="">
      </div>
      <choseTh />
      <thProduct />
      <thInfomation />
      <applicationArea />
    </div>
  </div>
</template>

<script>
import thProduct from './contentComponents/thProduct.vue'
import choseTh from './contentComponents/choseTh.vue'
import applicationArea from './contentComponents/applicationArea.vue'
import thInfomation from './contentComponents/thInfomation.vue'

export default {
  name: "mainContent",
  components: { thProduct, choseTh, applicationArea, thInfomation }
};
</script>

<style lang="scss" scoped>
.contentBox {
  font-size: 1.6rem;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 10rem;

  .content {
    width: 100%;
    position: relative;
  }

  .carouselImg {
    width: 100%;
    height: 100%;
  }
  
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 1.4rem;
  opacity: 0.75;
  line-height: 20rem;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>