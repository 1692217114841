<template>
  <div class="mainpage">
    <topMenu />
    <div class="content">
      <router-view /> 
    </div>
    <contactUs />
    <Myfooter />
  </div>
</template>

<script>
import topMenu from '@/components/topMenu.vue'
import contactUs from '@/components/contactUs.vue'
import Myfooter from '@/components/myFooter'

export default {
  name: 'mainPage',
  components: {topMenu, contactUs, Myfooter}
}
</script>

<style lang="scss" scoped>
.mainpage {
  background-color: #f4f4f4;  
  min-height: 100%;
  height: 100%;

  .content {
    height: 100%;
  }
}
</style>

