import Vue from 'vue'
import VueRouter from 'vue-router'
import mainContent from '@/components/mainContent.vue'
import contactUsPage from '@/pages/contactUsPage.vue'
import tehaoInfo from '@/pages/tehaoInfo.vue'
import tehaoInfoDetail from '@/components/tehaoInfoComponents/infoDetai.vue'
import thProduct from '@/pages/thProduct.vue'
import thProductDetail from '@/pages/thProductDetail.vue'
import noPage from '@/pages/noPage.vue'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: mainContent,
            name: 'aboutTh',
            meta: {
                title: '特浩电子-贴片电阻电容供应商-配套二极管·三极管·芯片'
            }
        },{
            path: '/aboutTh',
            component: mainContent,
            name: 'aboutTh',
            meta: {
                title: '特浩电子-关于特浩'
            }
        },{
            path: '/contactUs',
            component: contactUsPage,
            name: 'contactUs',
            meta: {
                title: '特浩电子-联系特浩'
            }
        },{
            path: '/tehaoInfo',
            component: tehaoInfo,
            name: 'tehaoInfo',
            meta: {
                title: '特浩电子-特浩资讯'
            }
        },{
            path: '/tehaoInfoDetail',
            component: tehaoInfoDetail,
            name: 'tehaoInfoDetail'
        },{
            path: '/thProduct',
            component: thProduct,
            name: 'thProduct',
            meta: {
                title: '特浩电子-特浩产品'
            }
        },{
            path: '/thProductDetail',
            component: thProductDetail,
            name: 'thProductDetail',
            meta: {
                title: '特浩电子-特浩产品详情'
            }
        },{
            path: '/404',
            component: noPage,
            name: 'noPage',
            meta: {
                title: '特浩电子-贴片电阻电容供应商-配套二极管·三极管·芯片'
            }
        },{
            path: '*',
            redirect: '/404',
            hidden: true,
            meta: {
                title: '特浩电子-贴片电阻电容供应商-配套二极管·三极管·芯片'
            }
        }
    ]
})

router.beforeEach((to, from, next) => {
    if(to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

export default router