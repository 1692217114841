import { render, staticRenderFns } from "./thProduct.vue?vue&type=template&id=01fc9830&scoped=true&"
import script from "./thProduct.vue?vue&type=script&lang=js&"
export * from "./thProduct.vue?vue&type=script&lang=js&"
import style0 from "./thProduct.vue?vue&type=style&index=0&id=01fc9830&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01fc9830",
  null
  
)

export default component.exports