<template>
  <div class="wrapper_content thBorder">
    <div>
      <p class="th_main_title"><span>上海特浩电子科技有限公司</span></p>
      <p class="content1">特浩电子前身为深圳美隆电子上海分公司，深耕于电子元器件的销售20余年，拥有更源头的货源，与源头厂家建立稳定的长期合作&emsp;<span class="highLight">价格优惠！</span></p>
      <p class="content1">在上海和深圳设置大型仓储，常年大量备货，型号齐全。如遇型号紧缺，工厂可加急生产，交期明确&emsp;<span class="highLight">型号齐全！</span></p>
      <p class="content1">每一颗物料都是工厂直供或由品牌代理提供，专业的技术团队对出手的物料进行质量把关&emsp;<span class="highLight">品质保障！</span></p>
    </div>
    <div class="th_chose_top">
      <ul>
        <li>
          <div class="pic">
            <el-image
              style="width: 3.2rem;height: 3.2rem;"
              :src="require('@/assets/img/th_task.png')"
              fit="scale-down"
              alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
              lazy
            />
          </div>
          <p>紧急采购需求</p>
        </li>
        <li>
          <div class="pic">
            <el-image
              style="width: 3.2rem;height: 3.2rem;"
              :src="require('@/assets/img/th_wl.png')"
              fit="scale-down"
              alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
              lazy
            />
          </div>
          <p>物料清单配套</p>
        </li>
        <li>
          <div class="pic">
            <el-image
              style="width: 3.2rem;height: 3.2rem;"
              :src="require('@/assets/img/th_gy.png')"
              fit="scale-down"
              alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
              lazy
            />
          </div>
          <p>可小批量供应</p>
        </li>
        <li>
          <div class="pic">
            <el-image
              style="width: 3.2rem;height: 3.2rem;"
              :src="require('@/assets/img/th_pz.png')"
              fit="scale-down"
              alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
              lazy
            />
          </div>
          <p>品质保障</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'chsoeTh'
}
</script>

<style lang="scss" scoped>
.wrapper_content {
  width: 80%;
  margin: 1rem auto;
  flex-flow: column;
  align-items: center;
  text-align: center;
  padding-top: 4px;

  .thBorder {
    padding-bottom: 1px;
  }

  .th_chose_top {
    padding: 3.4rem 0;
    border-bottom: 0.1rem solid #0283c9;
    width: 100%;

    ul {
      display: block;
      padding: 0;
      margin: 0;
      list-style: none;
      width: 98%;
      display: flex;
      flex-flow: row;
      justify-content: center;

      li {
        text-align: center;
        position: relative;
        display: inline-block;
        border-right: 0.1rem solid #0283c9;
        width: 22%;

        .pic {
          width: 8.5rem;
          height: 8.5rem;
          border: solid 0.1rem #0283c9;
          text-align: center;
          border-radius: 50%;
          line-height: 8.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 1.5rem auto;
          margin-top: 0rem;

          img {
            width: 3.2rem;
            height: 3.2rem;
          }
        }

        p {
          font-size: 1.5rem;
          color: #666666;
        }
      }
    }
    
    ul :last-child {
      border-right: 0px
    }
  }

  .content1 {
    text-align: center;
    text-indent: 2em;
    width: 80%;
    margin: 1.6rem auto;
  }
}
</style>