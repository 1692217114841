<template>
  <div>
    <img
      src="@/assets/img/banner2.png"
      style="width: 100%;"
      alt=""
    />
    <div class="wrapper_content">
      <p class="th_main_title"><span>特浩</span>产品</p>
      <div class="top">
        <ul>
          <li
            :class="activeMenu === 'sup' ? 'active' : ''"
            @click="switchMenu('sup')"
          >
            美隆SUP
          </li>
          <li
            :class="activeMenu === 'samsung' ? 'active' : ''"
            @click="switchMenu('samsung')"
          >
            三星Samsung
          </li>
          <li
            :class="activeMenu === 'mjda' ? 'active' : ''"
            @click="switchMenu('mjda')"
          >
            美键达MJDA
          </li>
          <li
            :class="activeMenu === 'jscj' ? 'active' : ''"
            @click="switchMenu('jscj')"
          >
            长电/长晶JSCJ
          </li>
          <li
            :class="activeMenu === 'other' ? 'active' : ''"
            @click="goTB"
          >
            其他
          </li>
        </ul>
      </div>
      <div v-if="activeMenu === 'sup'" class="proContent">
        <div v-for="item in proSUP" :key="item.index" class="content" @click="goDetail(item)">
          <div class="img">
            <el-image
              class="proPic"
              :src="item.img"
              fit="contain"
              alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
              lazy
            />
            <p id="productTitle">{{ item.title }}</p>
            <p id="proDescribe">
              产品型号：{{ item.type }} <br />封装尺寸：{{ item.size }}
            </p>
          </div>
          <a
            href="http://wpa.qq.com/msgrd?v=3&uin=3002947144&site=qq&menu=yes"
            target="_blank"
            >立即咨询</a
          >
        </div>
      </div>
      <div v-if="activeMenu === 'samsung'" class="proContent">
        <div v-for="item in proSamsung" :key="item.index" class="content" @click="goDetail(item)">
          <div class="img">
            <el-image
              class="proPic"
              :src="item.img"
              fit="contain"
              alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
              lazy
            />
            <p id="productTitle">{{ item.title }}</p>
            <p id="proDescribe">
              产品型号：{{ item.type }} <br />封装尺寸：{{ item.size }}
            </p>
          </div>
          <a
            href="http://wpa.qq.com/msgrd?v=3&uin=3002947144&site=qq&menu=yes"
            target="_blank"
            >立即咨询</a
          >
        </div>
      </div>
      <div v-if="activeMenu === 'mjda'" class="proContent">
        <div v-for="item in proMJDA" :key="item.index" class="content" @click="goDetail(item)">
          <div class="img">
            <el-image
              class="proPic"
              :src="item.img"
              fit="contain"
              alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
              lazy
            />
            <p id="productTitle">{{ item.title }}</p>
            <p id="proDescribe">
              产品型号：{{ item.type }} <br />封装尺寸：{{ item.size }}
            </p>
          </div>
          <a
            href="http://wpa.qq.com/msgrd?v=3&uin=3002947144&site=qq&menu=yes"
            target="_blank"
            >立即咨询</a
          >
        </div>
      </div>
      <div v-if="activeMenu === 'jscj'" class="proContent">
        <div v-for="item in proJSCJ" :key="item.index" class="content" @click="goDetail(item)">
          <div class="img">
            <el-image
              class="proPic"
              :src="item.img"
              fit="contain"
              alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
              lazy
            />
            <p id="productTitle">{{ item.title }}</p>
            <p id="proDescribe">
              产品型号：{{ item.type }} <br />封装尺寸：{{ item.size }}
            </p>
          </div>
          <a
            href="http://wpa.qq.com/msgrd?v=3&uin=3002947144&site=qq&menu=yes"
            target="_blank"
            >立即咨询</a
          >
        </div>
      </div>
      <div v-if="activeMenu === 'other'" class="proContent">
        <div v-for="item in proOther" :key="item.index" class="content">
          <div class="img">
            <el-image
              class="proPic"
              :src="item.img"
              fit="contain"
              alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309"
              lazy
            />
            <p id="productTitle">{{ item.title }}</p>
            <p id="proDescribe">
              产品型号：{{ item.type }}
            </p>
            <p>
              封装尺寸：{{ item.size }}
            </p>
          </div>
          <a
            href="http://wpa.qq.com/msgrd?v=3&uin=3002947144&site=qq&menu=yes"
            target="_blank"
            >立即咨询</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeMenu: "sup",
      proSUP: [
        {
          // sup电容
          title: "C0805C0G101J500NTED-美隆贴片电容",
          type: "C0805C0G101J500NTED",
          size: "0805(2012)",
          img: require("@/assets/img/product/0805-100pF.png"),
          containValue: "100pF",
          voltage: "50V",
          precision: "±5%",
          material: "C0G",
          thickness: "0.6mm",
        },
        {
          // sup电容
          title: "C0805X7R104K500NTFD-美隆贴片电容",
          type: "C0805X7R104K500NTFD",
          size: "0805(2012)",
          img: require("@/assets/img/product/0805-100nF.png"),
          containValue: "100nF",
          voltage: "50V",
          precision: "±10%",
          material: "X7R",
          thickness: "0.85mm",
        },
        {
          // sup电容
          title: "C0805X7R392K500NTFD-美隆贴片电容",
          type: "C0805X7R392K500NTFD",
          size: "0805(2012)",
          img: require("@/assets/img/product/0805-3.9nF.png"),
          containValue: "3.9nF",
          voltage: "50V",
          precision: "±10%",
          material: "X7R",
          thickness: "0.85mm",
        },
        {
          // sup电容
          title: "C0805X7R333K500NTFD-美隆贴片电容",
          type: "C0805X7R333K500NTFD",
          size: "0805(2012)",
          img: require("@/assets/img/product/0805-33nF.png"),
          containValue: "33nF",
          voltage: "50V",
          precision: "±10%",
          material: "X7R",
          thickness: "0.85mm",
        },
        {
          // sup电容
          title: "C0805X7R223K500NTFD-美隆贴片电容",
          type: "C0805X7R223K500NTFD",
          size: "0805(2012)",
          img: require("@/assets/img/product/0805-22nF.png"),
          containValue: "22nF",
          voltage: "50V",
          precision: "±10%",
          material: "X7R",
          thickness: "0.85mm",
        },
        {
          // 第一个单独处理
          title: "RR2012(0805)L334JT-美隆贴片电阻",
          type: "RR2012(0805)L334JT",
          size: "0805(2012)",
          img: require("@/assets/img/product/0805-330KJ.png"),
          value: "330KΩ",
          power: "1/8W",
          precision: "±5%",
        },
        {
          title: "RR2012(0805)L1200FT-美隆贴片电阻",
          type: "RR2012(0805)L1200FT",
          size: "0805(2012)",
          img: require("@/assets/img/product/0805-120RF.png"),
          value: "120Ω",
          power: "1/8W",
          precision: "±1%",
        },
        {
          title: "RR3216(LE06)L200JT-美隆贴片电阻",
          type: "RR3216(LE06)L200JT",
          size: "1206(3216)",
          img: require("@/assets/img/product/1206-20RJ.png"),
          value: "20Ω",
          power: "1/4W",
          precision: "±5%",
        },
        {
          title: "RR1005(0402)L4022FT-美隆贴片电阻",
          type: "RR1005(0402)L4022FT",
          size: "0402(1005)",
          img: require("@/assets/img/product/0402-40.2KF.png"),
          value: "40.2KΩ",
          power: "1/16W",
          precision: "±1%",
        },
        {
          title: "RR1608(0603)L3603FT-美隆贴片电阻",
          type: "RR1608(0603)L3603FT",
          size: "0603(1608)",
          img: require("@/assets/img/product/0603-360KF.png"),
          value: "360KΩ",
          power: "1/10W",
          precision: "±1%",
        },
      ],
      proSamsung: [
        {
          title: "CL21A475KBQNNNE-三星贴片电容",
          brand: "三星(Samsung)",
          type: "CL21A475KA",
          size: "0805(2012)",
          img: require("@/assets/img/product/2012-4.7uF.png"),
          containValue: "4.7uF",
          voltage: "50V",
          precision: "±10%",
          material: "X5R",
          thickness: "1.25mm",
        },
        {
          title: "CL21B225KAFNNNE-三星贴片电容",
          brand: "三星(Samsung)",
          type: "CL21B225KAFNNNE",
          size: "0805(2012)",
          img: require("@/assets/img/product/2012-2.2uF.png"),
          containValue: "2.2uF",
          voltage: "25V",
          precision: "±10%",
          material: "X7R",
          thickness: "1.25mm",
        },
        {
          // samsung
          title: "CL21B105KBFNNNE-三星贴片电容",
          brand: "三星(Samsung)",
          type: "CL21B105KBFNNNE",
          size: "0805(2012)",
          img: require("@/assets/img/product/2012-1uF.png"),
          containValue: "1uF",
          voltage: "50V",
          precision: "±10%",
          material: "X7R",
          thickness: "1.25mm",
        },
        {
          title: "CL21A106KAYNNNE-三星贴片电容",
          brand: "三星(Samsung)",
          type: "CL21A106KAYNNNE",
          size: "0805(2012)",
          img: require("@/assets/img/product/2012-10uF.png"),
          containValue: "10uF",
          voltage: "25V",
          precision: "±10%",
          material: "X5R",
          thickness: "1.25mm",
        },
        {
          title: "CL21B224KBFNFNE-三星贴片电容",
          brand: "三星(Samsung)",
          type: "CL21B224KBFNFNE",
          size: "0805(2012)",
          img: require("@/assets/img/product/2012-220nF.png"),
          containValue: "220nF",
          voltage: "50V",
          precision: "±10%",
          material: "X7R",
          thickness: "1.25mm",
        },
      ],
      proMJDA: [
        {
          title: "M7-美键达二极管",
          type: "M7",
          brand: "美键达(MJDA)",
          img: require("@/assets/img/product/m7.png"),
          size: "DO-214AC",
        },
        {
          title: "SS24-美键达二极管",
          type: "SS24",
          brand: "美键达(MJDA)",
          img: require("@/assets/img/product/ss24.png"),
          size: "DO-214AC",
        },
        {
          title: "SS14-美键达二极管",
          type: "SS14",
          brand: "美键达(MJDA)",
          img: require("@/assets/img/product/ss14.png"),
          size: "DO-214AC",
        },
        {
          title: "S8050 J3Y-美键达三极管",
          type: "J3Y",
          brand: "美键达(MJDA)",
          img: require("@/assets/img/product/s8050-J3Y.png"),
          size: "SOT-23",
        },
        {
          title: "SS8550 Y2-美键达三极管",
          type: "Y2",
          brand: "美键达(MJDA)",
          img: require("@/assets/img/product/ss8550-Y2.png"),
          size: "SOT-23",
        },
      ],
      proJSCJ: [
        {
          title: "1N4148WS-长电/长晶开关二极管",
          type: "1N4148WS",
          brand: "长电/长晶（JCET）",
          img: require("@/assets/img/product/1N4148WS.png"),
          size: "SOD-323",
        },
        {
          title: "1N4148W T4-长电/长晶开关二极管",
          type: "1N4148W T4",
          brand: "长电/长晶（JCET）",
          img: require("@/assets/img/product/1N4148W-T4.png"),
          size: "SOT-123",
        },
        {
          title: "CJ431-长电/长晶稳压电路",
          type: "CJ431",
          brand: "长电/长晶（JCET）",
          img: require("@/assets/img/product/CJ431.png"),
          size: "SOT-23",
        },
        {
          title: "BZT52C8V2-长电/长晶稳压二极管",
          type: "BZT52C8V2",
          brand: "长电/长晶（JCET）",
          img: require("@/assets/img/product/BZT52C8V2.png"),
          size: "SOD-123",
        },
        {
          title: "SS8050-长电/长晶稳压三极管",
          type: "SS8050",
          brand: "长电/长晶（JCET）",
          img: require("@/assets/img/product/ss8050.png"),
          size: "SOT-23",
        },
      ],
      proOther: [],
    };
  },
  methods: {
    switchMenu(menu) {
      this.activeMenu = menu;
    },
    goTB() {
      window.open('https://itehao.1688.com/page/index.html?spm=0.0.wp_pc_common_header_companyName_undefined.0', '_blank');
    },
    goDetail(clickObj) {
      sessionStorage.setItem('clickObj', JSON.stringify(clickObj))
      this.$router.push('/thProductDetail')
      window.scrollTo(0,0)
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  width: 100%;
  margin: 0 auto;

  ul {
    list-style: none;
    position: relative;
    padding: 0;
    width: 100%;
    min-height: 7rem;
    font-size: 2.2rem;
    display: flex;

    li {
      width: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      text-align: center;
      border-top: solid .1rem #e6e6e6;
      border-bottom: solid .1rem #e6e6e6;
      border-right: solid .1rem #e6e6e6;
      position: relative;
      line-height: 7rem;
      box-sizing: border-box;
      transition: all 0.7s;
    }

    li.active {
      background-color: #0083ca;
      border-right: 0px;
    }

    li:hover {
      background-color: #0083ca;
      border-right: 0px;
    }
    li.active::after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-width: .8rem;
      border-style: solid;
      border-color: #0083ca transparent transparent transparent;
      position: absolute;
      bottom: -1.5rem;
      left: 50%;
      margin-left: -.8rem;
      z-index: 2;
    }
  }
}
.proContent {
  width: 100%;
  min-height: 50rem;

  .content {
    width: 32.3%;
    border: solid .1rem #e6e6e6;
    display: inline-block;
    // float: left;
    margin-right: 1%;
    margin-bottom: 1%;
    transition: all 0.8s;
    box-sizing: border-box;
    padding-top: 1rem;

    .img {
      padding: 1rem;
      padding-bottom: 0rem;
      .proPic {
        width: 100%;
        display: block;
        transition: scale 0.8s;
      }
    }
    a {
      text-decoration: none;
      font-size: 1.5rem;
      color: #333;
      cursor: pointer;
      transition: color 0.7s;
      display: block;
      width: 100%;
      background-color: #0083ca;
      line-height: 5rem;
    }
    a:hover {
      color: #fff;
      font-weight: 500;
    }

    #productTitle {
      font-weight: 400;
      font-size: 1.6rem;
      color: #333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      transition: all 0.8s;
    }

    #proDescribe {
      height: 4rem;
      font-size: 1.4rem;
      color: #666;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  .content:hover {
    box-shadow: 0.5rem 0.5rem 4rem #888888;
  }
  .content:hover #productTitle {
    color: #0083ca;
  }
}

</style>
