<template>
<div>
  <el-page-header @back="goBack" />
<div class="flexbox1">
  <div>
    <div class="wrapper_content">
      <p class="th_main_title animate__animated animate__backInLeft"><span>{{ clickTitle }}</span></p>
    </div>
    <!-- <div class="content" v-html="clickObj.content" /> -->
    <!-- 贴片电阻知识分享 -->
    <div v-if="clickId === 1" class="content">
      <p class="subtitle">合金电阻特性</p>
      <p>合金电阻作为电流载体，低阻值，高精密，低温度系数，耐较高的脉冲电流，大功率等特点阻值精准，温度稳定性，产品的安全性，稳定性明显高于普通的陶瓷电阻。</p>
      <p>同时金属的导热性强也是一大优势。高导热材质可大幅降低电路板上的散热面积,特别是在电源以及其它相关产品的应用中，瞬间的冲击电流，短路电流或脉冲电流产生时，合金当做首选作为电流介质来检测电流。</p>
      <p class="subtitle">应用领域</p>
      <p>主要应用于医疗仪器，量测设备，汽车相关控制板，玩具充电控制板，电子电力设备，UPS，高频放大器，电源类，变频器，灯具，手机，屏幕，电机, 计算机周边控制板，笔记本、电脑主板,电源供应器、逆变器/转换器、锂电池保护板、移动电源（充电宝）以及LED驱动器，LCD控制板。</p>
      <p class="subtitle">性能指标</p>
      <p>合金电阻关键的五项性能指标：P(功率)、V(电压)、I(电流)、R(阻值)、TCR(温漂)电流采样电阻的计算公式：I=√P/R</p>
      <p>1、功率：贴片电阻的功率是指通过电流时由于焦耳热电阻产生的功率。根据焦耳定律：P=I2 R</p>
      <p>2、额定功率 ：是指在某个温度下最大允许使用的功率，通常指环境温度为70°C时的额定功率 </p>
      <p>3、额定电压：可以根据以下公式求出额定电压V=√P× R</p>
      <p>4、最高工作电压 ：允许加载在贴片电阻两端的最高电压 </p>
      <p>5、额定电流:I=√P/R</p>
    </div>
    <!-- 贴片电阻性能指标 -->
    <div v-if="clickId === 2" class="content">
      <p class="subtitle">电阻的五项性能指标 </p>
      <p>1、体积：表示电阻的长*宽*高</p>
      <p>2、阻值：表示这个电阻对电流流动阻挡力的大小</p>
      <p>3、功率：表示电阻在时间单位内所做的功</p>
      <p>4、误差：表示电阻的精度温漂：表示电阻受温度影响的大小</p>
      <p>5、温漂：表示电阻受温度影响的大小</p>
      <p class="subtitle">贴片电阻的体积</p>
      <img :src="require('@/assets/img/dztj.png')" alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309" />
      <img :src="require('@/assets/img/gtjckg.png')" alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309" />
      <p class="subtitle">阻值及丝印</p>
      <p><strong>阻值</strong> ：它代表这个电阻对电流流动阻挡力的大小</p>
      <p>单位是：mΩ（毫欧）、Ω（欧姆）、KΩ（千欧）、MΩ（兆欧）</p>
      <p>它们的倍率是1000倍</p>
      <p>即1MΩ=1000KΩ  1KΩ=1000Ω  1Ω=1000mΩ</p>
      <p style="margin-top:10rem">
        <strong>丝印</strong> ：它是阻值在电阻物料上的打字，通过丝印可以得知此物料的阻值、误差温漂。
      </p>
      <p>第一类：01005、0201、0402因本体太小故而没有在上面打上丝印。</p>
      <p>第二类：0805体积以上（包括0805）常规阻值丝印±5%在物料上显示3位数，±1%在物料上显示4位数。</p>
      <p>第三类：0603体积因本体太小，在物料上无论±1%还是±5%都是显示3位数。0603体积±5%按0805 ±5%的表示方法法解读。丝印带下划线的按0805 ±5%的表示法解读。丝印出现字母的按0603 ±1%丝印代码表解读。</p>
      <p>第四类：0805体积以上（包含）超低阻、低阻的丝印都是4位数，并且以R开头，0603J低阻是3位数，以R开头，0603超低阻3位数，小数点后有两位以R开头，小数点后有三位以0开头。低阻与超低阻。</p>
      <p class="subtitle">功率 </p>
      <p><strong>功率</strong>：是指物体在时间单位内所做的功，表示做功快慢的物理量，用W来表示。</p>
      <img :src="require('@/assets/img/dzgl.png')" alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309" />
      <p><strong>最大工作电压</strong>：贴片电阻长时间能承受的电压</p>
      <p><strong>最大负载电压</strong>：贴片电阻瞬时间5S能承受的电压</p>
      <p>最大工作电压指的<span style="text-decoration: underline;">是该体积系列最大承受的长时间工作电压，但并非所有阻值都能达到规格书中最大工作电压。</span></p>
      <p class="subtitle">误差 </p>
      <img :src="require('@/assets/img/dzjd.png')" alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309" />
      <p class="subtitle">温漂 </p>
      <p><strong>温度系数（温漂）</strong> ：单位PPM表示百万分之一，表示电阻受温度变化影响的程度</p>
      <img :src="require('@/assets/img/dzwp.png')" alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309" />
      <p><strong>温漂的计算</strong> ：电阻每受1℃的温度变化就会带来电阻阻值的变化。</p>
      <p>它的计算是原本的阻值加上或减去（阻值×**PPM)</p>
    </div>
    <!-- 贴片电阻知识分享 -->
    <div v-if="clickId === 3" class="content">
      <p class="subtitle">什么是电阻？ </p>
      <p>在电路中限制电流或将电能转换为热能的电子元器件 </p>
      <p>电阻（Resistance，通常用“R”表示），在物理学中表示导体对电流阻碍作用的大小。导体的电阻越大，表示导体对电流的阻碍作用越大。</p>
      <p>电阻的主要物理特征是变电能为热能，也可说它是一个耗能元件，电流经过它就产生内能。电阻在电路中通常起分压、分流的作用。对信号来说，交流与直流信号都可以通过电阻。</p>
      <p>导体的电阻通常用字母R表示，电阻的单位是欧姆（ohm），简称欧，符号是Ω（希腊字母，读作Omega)。</p>
      <div style="width: 100%;display:flex;align-item:center;justify-content: center">
        <img :src="require('@/assets/img/dz.png')" style="width:30%;magin:0 auto;" alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309" />
      </div>
      <p class="subtitle">什么是贴片电阻？ </p>
      <p>片式固定电阻器，俗称贴片电阻(SMD Resistor),是金属玻璃铀电阻器中的一种。是将金属粉和玻璃铀粉混合，采用丝网印刷法印在基板上制成的电阻器。</p>
      <p class="subtitle">特性及应用 </p>
      <p>1、体积小，重量轻，利于整机产品的小型化、微型化</p>
      <p>2、温度系数小，电性能稳定，可靠性高</p>
      <p>3、机械强度高，尺寸稳定，很适合SMT技术要求</p>
      <p>4、高频特性优异</p>
      <p>5、具有优异的适应波峰焊和回流焊，很适合SMT技术要求</p>
      <p>6、尺寸稳定，装配成本低并与自动贴装设备匹配好</p>
      <p>7、应用于移动通信、计算机、家用电器、玩具、医疗设备、LED灯及驱动电源、移动电源等领域。</p>
      <p class="subtitle">特点及应用 </p>
      <p>特点：体积小、重量轻、温度系数小、电性能稳定、可靠性能高、适合所有焊接工艺（适应回流焊与波峰焊 ）</p>
      <p>应用：移动通信、计算机、家用电器、玩具、医疗设备、LED灯及移动电源。 ）</p>
      <p class="subtitle">贴片电阻的制造流程 </p>
      <img :src="require('@/assets/img/dzzz.png')" style="width:100%" alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309" />

    </div>
    <!-- 贴片电容知识分享 -->
    <div v-if="clickId === 4" class="content">
      <p class="subtitle">什么是电容？ </p>
      <p>贴片电容是一种电容材质。贴片电容全称为：多层（积层，叠层）片式陶瓷电容器，也称为贴片电容，片容。贴片电容有两种表示方法，一种是英寸单位来表示，一种是毫米单位来表示。</p>
      <p>电容器，顾名思义，是“装电的容器”，是一种容纳电荷的器件。英文名称：capacitor。</p>
      <div style="width: 100%;display:flex;align-item:center;justify-content: center">
        <img :src="require('@/assets/img/dr.png')" style="width:30%;magin:0 auto;" alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309" />
      </div>
      <p class="subtitle">贴片电容参数</p>
      <p> C ：电容值(单位：F) </p>
      <p> ε ：真空介电率(8.84´10-12 F/m)  </p>
      <p>  K ：相对介电常数</p>
      <p>  S ：有效面积(m2) </p>
      <p>  d ：介电层厚度 (m)</p>
      <div style="width: 100%;display:flex;align-item:center;justify-content: center">
        <img :src="require('@/assets/img/drgs.png')" style="width:30%;magin:0 auto;" alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309" />
      </div>
      <p class="subtitle">电容的结构 </p>
      <div style="width: 100%;display:flex;align-item:center;justify-content: center">
        <img :src="require('@/assets/img/drjg.png')" style="width:30%;magin:0 auto;" alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309" />
      </div>
      <p class="subtitle">贴片电容的制造流程 </p>
      <img :src="require('@/assets/img/drzz.png')" style="width:100%" alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309" />
      <p class="subtitle">贴片电容成分 </p>
      <p>贴片电容包含电子陶瓷介质、镍内电极（铜内电极）、铜端电极、电镀镍、电镀锡等，这些成分中均不含有Rohs限定物质，完全符合欧盟环保Rohs条款。</p>
      <p class="subtitle">电容的五项性能指标 </p>
      <p>1.体积：表示电容的长*宽*高</p>
      <p>2.容值：电容的容值表示储存电能的能力的大小。</p>
      <p>3.耐压：电容能承受的最大直流工作电压</p>
      <p>4.档位：表示电容的精度</p>
      <p>5.材质：表示电容的填充介质</p>

    </div>
    <!-- 贴片电容知识分享 -->
    <div v-if="clickId === 5" class="content">
      <p>贴片电阻规格命名方法、分类、尺寸功率信息与通信有工程科技和专业资料。内地度贴片电阻的命名方法:182是5%精度阻值表示法：前两位数表示有效数字，第三位表示有多少个零，基本单位是Ω，182=1800Ω=1.8KΩ。</p>
      <p>如果有关于常规贴片电阻耐压的疑问，<a href="http://wpa.qq.com/msgrd?v=3&uin=3002947144&site=qq&menu=yes" target="_blank" class="goQQ">欢迎在线咨询QQ客服</a></p>
      <p>CR1206L182JT</p>
      <p>CR -表示贴片电阻产品型号</p>
      <p>182是5%精度阻值表示法：前两位数表示有效数字，第三位表示有多少个零，基本单位是Ω，182=1800Ω=1.8KΩ。</p>
      <p>1801是1%阻值表示法：前三位表示有效数字，第四位表示有多少个零，基本单位是Ω，1802=18000Ω=18KΩ。</p>
      <p>J -表示精度为5%</p>
      <p>F -表示精度为1%</p>
      <p>T -表示编带包装</p>
      <p>贴片电阻阻值误差精度有±1%、±2%、±5%、±10%精度，常规用的最多的是±1%和±5%，±5%精度的常规是用三位数来表示例 例512，前面两位是有效数字，第三位数2表示有多少个零，基本单位是Ω,这样就是5100欧，1000Ω=1KΩ,1000000Ω=1MΩ</p>
      <p>
        为了区分±5%，±1%的电阻，于是±1%的电阻常规多数用4位数来表示 ，这样前三位是表示有效数字，第四位表示有多少个零4531也就是4530Ω，也就等于4.53KΩ
      </p>
      <p>贴片电阻耐压请查阅下表：</p>
      <img :src="require('@/assets/img/dzny.png')" alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309">
    </div>
    <!-- 贴片电阻储存产品环境 -->
    <div v-if="clickId === 6" class="content">
      <p>贴片电容、二三极管、贴片保险丝有储存的要求，当然了贴片电阻也不例外</p>
      <p>如果有关于常规贴片电阻耐压的疑问，<a href="http://wpa.qq.com/msgrd?v=3&uin=3002947144&site=qq&menu=yes" target="_blank" class="goQQ">欢迎在线咨询QQ客服</a></p>
      <p>贴片电阻电容的保存和运输要求:</p>
      <p>非裸包装(说明：裸包装是指物料的原包装被拆分后的再存储。)</p>
      <p>存储期限：</p>
      <p>出货起1年</p>
      <p>1、防潮(说明：不要将贴片电容储存在高温和湿度高的地方，储存环境)</p>
      <p>①、 管控仓储的温度要求(15 ℃~ 35 ℃)、湿度要求(25 %~ 75 %)</p>
      <p>②、建议每天确认环境温湿度是否在要求范围内，发现异常时可以使用抽湿机或空调调节。</p>
      <p>2、防挤压/碰撞：</p>
      <p>搬运储存时防止外箱或卷盘发生形变</p>
      <p>最后需要指出的是，产品存储在高静电环境下或者阳光、雨、雪或凝结情况中产品性能和焊接连接可能恶化。</p>
      <img :src="require('@/assets/img/cchj.png')" alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309">
    </div>
    <!-- 什么是贴片电感？ -->
    <div v-if="clickId === 7" class="content">
      <p>什么是贴片电感？</p>
      <p>贴片电感(Chipinductors)，又称功率电感、大电流电感和表面安装高功率电感。它具有小型化、高质量、高能储存和低电阻的特点。一般来说，电子线路中的电感是中空线圈或带磁芯的线圈，只能通过较小的电流承受较低的电压。功率电感也包括中空线圈和带磁芯的线圈。其主要特点是用粗导线缠绕，可承受数十安、数百安、数千甚至数万安。</p>
      <img :src="require('@/assets/img/dg.png')" style="width:60%;margin:0 auto;display:flex" alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309">
      <p>如果有关于常规贴片电感的疑问，<a href="http://wpa.qq.com/msgrd?v=3&uin=3002947144&site=qq&menu=yes" target="_blank" class="goQQ">欢迎在线咨询QQ客服</a></p>
      <p>功率贴片电感分为磁罩和非磁罩，主要由磁芯和铜线组成。滤波和振动是电路中的主要功能。片式感应器主要有四种类型，即绕线感应器、叠层感应器、编织感应器和薄膜片式感应器。常用的有绕线感应器和层叠感应器。前一种是传统绕线感应器小型化的产物；后一种是采用多层印刷技术和层叠生产工艺制造的，体积小于绕线感应器，是电感元件领域的重点产品。</p>
      <p>贴片电感是用绝缘线绕制而成的电磁感应元件。属于常用的电感元件。贴片电感的功能:通过DC电阻交流是一种简单的说法。它将交流信号隔离、滤波或与电容、电阻等组成谐振电路。调谐和选频电感的功能:电感线圈与电容并联，可形成LC调谐电路。贴片电感在电路中的任何电流都会产生磁场，磁场的磁通量也会作用于电路。</p>
    </div>
    <!-- 什么是钽电容？ -->
    <div v-if="clickId === 8" class="content">
      <p>什么是钽电容？</p>
      <p>与一般电容相比，贴片钽电容的性能优异，体积小，又可以达到较大的电容量，在电源滤波、交流旁路等方面，稍有竞争产品。</p>
      <p>钽电解电容器具有储藏电量、进行充放电等性能，主要应用于滤波、能量贮存与转换，记号旁路，耦合与退耦以及作时间常数元件等。  </p>
      <p>贴片钽电容主要用于清除由芯片自身产生的各种高频信号对其他芯片的串扰，以便让各个芯片模块能够不受干扰的正常工作，在高频电子振荡线路中，贴片式电容与晶体振荡器等元件一起组成振荡电路，给各种电路提供所需的时钟频率。</p>
      <p>使用过程中要注意这种电容的性能特点，以便充分发挥其功能，多考虑下产品工作环境及发热温度，以及采取降额使用等措施，若使用不当会影响产品的工作寿命。</p>
      <img :src="require('@/assets/img/ddr.jpg')" style="width:60%;margin:0 auto;display:flex" alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309">
      <p>如果有关于钽电容的疑问，<a href="http://wpa.qq.com/msgrd?v=3&uin=3002947144&site=qq&menu=yes" target="_blank" class="goQQ">欢迎在线咨询QQ客服</a></p>
      <p><strong>使用注意事项：</strong></p>
      <p>1.钽电容器是有极性的电解电容器（有“+”符号端为正极），使用时不可反极性。反极性使用时会增加漏电或可能引起短路、甚至冒烟爆炸。</p>
      <p>2.不能应用的电路：高阻抗电压保持电路、耦合电路、时间常数电路、漏电流有影响的电路、用串联方式增加耐压的电路。</p>
      <p>3.不能超过额定电压使用，否则有可能引起短路。</p>
      <p>4.限制急速地充电或放电。建议在充、放电电路中加一个限流电阻，使冲击电流小于20A。</p>
      <p>5.在设计时，电容的容量、耐压、阻抗等要留一定的裕度，使更为安全、可靠。</p>
      <p>6.确认使用的温度范围在电容器的工作温度范围内。电源电流不超过容许的纹波电流，当超过容许的纹波电流时，电容器内部会增加热量，降低使用寿命。</p>
      <p>建议电容器施加的电压为额定电压的90%，若额定电压大于10V时，取额定电压的80%为好；若直流电压加上交变电压，则其峰值电压不能超过额定电压；若总的直流电压加上负峰值交变电压，不允许出现负压。</p>
    </div>
    <!-- 什么是钽电容？ -->
    <div v-if="clickId === 9" class="content">
      <p>贴片电阻的精度</p>
      <p>说到贴片电阻，大多数人会关注它的阻值，功率，耐压等，会把精度给忘掉，但其实精度也是非常重要的。</p>
      <p>以智能家电为例：贴片电阻精度的高低会直接影响电器设备的性能和效果，主要原因在于电阻的精度影响着对电流和电压的调控能力，对于智能家电的效果还是影响很大的，是产品精度的重要保障。</p>
      <p>经研究发现0.5%精度贴片电阻，是非常适合智能家居产品应用的，当然不同的电子设备对于贴片电阻的精度的选择也是不同的。</p>
      <img :src="require('@/assets/img/dzjd.jpg')" style="width:60%;margin:0 auto;display:flex" alt="专业销售：贴片电阻电容-二三极管,欢迎致电：021-61032309">
      <p>如果有关于贴片电阻的疑问，<a href="http://wpa.qq.com/msgrd?v=3&uin=3002947144&site=qq&menu=yes" target="_blank" class="goQQ">欢迎在线咨询QQ客服</a></p>
      <p>贴片电阻阻值误差精度：</p>
      <p>主要有±1%、±2%、±5%、±10精度。</p>
      <p>含义：</p>
      <p>J - 表示精度为5%</p>
      <p>F - 表示精度为1%</p>
      <p>T - 表示编带包装</p>
      <p>特性：体积小，重量轻、适应再流焊与波峰焊、电性能稳定，可靠性高、装配成本低，并与自动装贴设备匹配、机械强度高，高频特性优越。</p>
    </div>
    <!-- 金属膜电阻和碳膜电阻的区别 -->
    <div v-if="clickId === 10" class="content">
      <strong>金属膜电阻和碳膜电阻</strong>
      <p>贴片电阻又名片式固定电阻器，是金属玻璃釉电阻器中的一种。是将金属粉和玻璃釉粉混合，采用丝网印刷法印在基板上制成的电阻器。耐潮湿和高温， 温度系数小。可大大节约电路空间成本，使设计更精细化。</p>
      <p>1、金属膜电阻是迄今为止应用较为广泛的电阻，其精度高，性能稳定，结构简单轻巧。在电子行业和高精度要求下的军事航天等领域发挥不可忽视的作用。是用镍铬或类似的合金真空电镀技术，着膜于白瓷棒表面，经过切割调试阻值，以达到要求的精密阻值。有着广泛的阻值范围，有着精密阻值，公差范围小的特性。</p>
      <p>而薄膜电阻器是用类蒸发的方法将一定电阻率材料蒸镀于绝缘材料表面制成，一般这类电阻常用的绝缘材料是陶瓷基板。是从高温真空中分离出有机化合物之碳，紧密附着于瓷棒表面之碳膜体，而加以适当之接头后切割调适而成，并在其表面涂上环氧树脂密封以保护。碳膜电阻从外观上，金属膜的为五个环（1%），碳膜的为四环（5%）。金属膜的为蓝色，碳膜的为土黄色或是其他的颜色。</p>
      <strong>2、金属膜电阻与碳膜电阻的区别</strong>
      <p>1、碳膜电阻具有负的温度系数，而金属膜电阻具有较小的正温度系数;</p>
      <p>2、碳膜电阻是普通电阻，精度一般不是很高，而金属膜电阻精度比碳膜电阻高，但是由于现在工艺的提高，这种方法有时候不是很可取;</p>
      <p>3、碳膜电阻外观多为土黄色或者粉红色，而金属膜电阻外观大多数是蓝色;</p>
      <p>4、通过观看保护漆颜色区分：黑色的保护膜为碳膜电阻，而亮白色的保护膜为金属膜电阻。</p>
      <strong>3、金属膜电阻器的特性：</strong>
      <p>①耐热性好，额定工作温度为70℃，最高工作温度可达155℃。</p>
      <p>②电压稳定性好，温度系数小。</p>
      <p>③工作频率范围宽，噪声电动势很小，可在高频电路中使用。</p>
      <p>④在相同的功率条件下，它比碳膜电阻器体积小很多，约为碳膜电阻器的一半。</p>
      <p>⑤它可以通过切割蝶、纹方法进行精密阻值调节，精度可达±0.5%、±0.05%。⑥阻值范围很宽，可制成1Ω-1000MΩ的电阻器。</p>
      <p>⑦额定的功率有0.125W、0.25W、0.5W、1W、2W、10W及25W等。</p>
      <p>⑧脉冲负荷稳定性较差，不如碳膜电阻器。</p>
      <p>⑨应用非常广泛，适用于交流、直流及脉冲电路。</p>
      <strong>4、碳膜电阻的特性：</strong>
      <p>①精度：2% 5% ，精度高，通过对膜切割螺纹可调整阻值，制成精密电阻器。②阻值范围：阻值范围宽，一般为2.1Ω~10MΩ。</p>
      <p>③标称阻值：E-48 。</p>
      <p>④极限电压高。</p>
      <p>⑤极好的长期稳定性，电压的改变对阻值的影响极小，且具有负温度系数。</p>
      <p>⑥包装方式有带装、散装。</p>
      <p>⑦高频特性好，可制成高频电阻器和超高频电阻器，固有噪声电动势小，在10UV/V以下。</p>
      <p>⑧额定功率有1/8W、1/4W、1/2W、1W、2W、5W、10W。</p>
      <p>⑨脉冲负荷稳定，对脉冲的适应性好。应用范围非常广泛，适用于交流、直流和脉冲电路。</p>
    </div>
    <div class="switchMenu">
      <span :class="activeMenu === 'chose' ? 'activeSpan' : ''" @click="changeMenu('chose')">选择特浩</span>
      <span :class="activeMenu === 'contact' ? 'activeSpan' : ''" @click="changeMenu('contact')">联系特浩</span>
    </div>
    <div style="fontSize:1.6rem;">
      <choseTh v-if="activeMenu === 'chose'" />
      <contactUsPage class="my100width" v-if="activeMenu === 'contact'" />
    </div>
  </div>
</div>
</div>
</template>

<script>
import choseTh from '@/components/contentComponents/choseTh.vue'
import contactUsPage from '@/pages/contactUsPage.vue'

export default {
  name: 'infoDetai',
  props: {},
  components: { choseTh, contactUsPage },
  data() {
    return {
      activeMenu: 'chose' // chose 选择特浩 contact 联系特浩
    }
  },
  created() {
    document.title = '特浩电子-特浩资讯-' + this.$route.query.title
  },
  computed: {
    clickId() {
      console.log(this.$route.query.id)
      return Number(this.$route.query.id) || 1
    },
    clickTitle() {
      return this.$route.query.title || ''
    }
  },
  methods: {
    goBack() {
      // this.$router.go(0)
      // this.$emit('changeFlag')
      this.$router.push({ path:'/tehaoInfo' })
      window.scrollTo(0,0)
    },
    changeMenu(menu) {
      this.activeMenu = menu
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/.mainBox .flexbox1 {
  width:100%
}
.switchMenu {
  background-color: #8c8c8c;
  cursor: pointer;
  width: 100%;
  margin: 2rem 0; 
  line-height: 8rem;
  text-align: center;
  color: #fff;
  font-size: 1.7rem;

  span {
    width:50%;
    display: inline-block;
  }
}
.activeSpan {
  background-color: #0083ca;
}
.wrapper_content {
  width: 100%;
}
.flexbox1 {
  width:70%;
  margin: 0 auto
}
.goQQ {
  color: #0283c9;
}
.content {
  width: 100%;
  text-align: left;
  margin-top: 5rem;
  padding: 0px 5rem 10rem 5rem;
  box-sizing: border-box;
  line-height: 6rem;
  text-indent: 2em;
  font-size: 1.8rem;

  .subtitle {
    text-indent: 0em;
    text-align: center;
    font-size: 2.5rem;
    color: #ca2524;
    font-weight: 700;
  }

  img {
    width: 100%;
  }
}
</style>