<template>
  <div id="app">
    <mainPage />
  </div>
</template>

<script>
import mainPage from './pages/mainPage.vue'

export default {
  name: 'App',
  components: {
    mainPage
  }
}
</script>

<style>
</style>
